import React, { Component } from 'react';
import styled from 'styled-components';
import NavBar from '../Components/NavBar.jsx';
import IntroBlurb from '../Components/IntroBlurb.jsx';
import ARProjectsGrid from '../Components/ARProjectsGrid.jsx';
import AboutScreen from './AboutScreen.jsx';
import { DefaultBlock } from '../Components/PageComponents.jsx';
import otherCritica from '../Assets/otherCritica.png';
import otherRhymepad from '../Assets/otherRhymepad.png';
import otherFoodgroup from '../Assets/otherFoodgroup.png';
import { Link } from 'react-router-dom';

const OverlayContainer = styled.div`
  position: relative;
  margin: 0 auto;

  position: relative;
  width: 100%;

  @media(max-width: 800px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  width: 90%;
  @media (max-width: 1200px) {
    width: 700px;
  }
  @media (max-width: 800px) {
    width: 90%;
  }
`;

const ContentHeader = styled.h2`
  text-align: left;
  margin-bottom: 20px;
`;

const ContentSubheader = styled.p`
  text-align: center;
  margin-top: 0;
  padding-top: 0;
  margin-bottom: 30px;
  font-size: 1em;
  opacity: 0.5;
`;

const GradientText = styled.h1`
  margin-top: 40px;
  padding-top: 0;
  margin-bottom: 20px;
  color: #2AC45E;
  background: -webkit-linear-gradient(20deg, black, #0F9692);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (prefers-color-scheme: dark) {
    background: -webkit-linear-gradient(20deg, white, #78AFAF);  
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

class ARScreen extends Component {

  state = {
    showAbout: false
  }

  onAboutClicked(event) {
    this.setState({ showAbout: true })
  }

  onAboutDismissed() {
    this.setState({ showAbout: false })
  }

  constructor(props) {
    super(props);
    this.onAboutClicked = this.onAboutClicked.bind(this);
    this.onAboutDismissed = this.onAboutDismissed.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
    document.title = 'Punya Chatterjee';
  }

  render() {

    const { showAbout } = this.state;

    return (
        <OverlayContainer>
            <DefaultBlock>
              <NavBar usePlain={true}/>
            </DefaultBlock>
            <ContentContainer>
              <GradientText>Augmented Reality</GradientText>
              <ContentSubheader>Prototypes of concepts I have in AR</ContentSubheader>
              <ARProjectsGrid />
            </ContentContainer>
        </OverlayContainer>
    );
  }
}

export default ARScreen;
