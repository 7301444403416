import React, { Component } from 'react';
import styled from 'styled-components';
import pcLogo from '../Assets/pcLogo.png';
import pcLogoDark from '../Assets/pcLogoDark.png';
import pcLogoPlain from '../Assets/pcLogoPlain.png';
import pcLogoPlainDark from '../Assets/pcLogoPlainDark.png';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  @media (max-width: 800px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

function Logo(props) {
  const BaseImage = styled.img`
    width: 30px;
    height: auto;
    padding: 5px 10px 5px 10px;
    border-radius: 0px;
    @media (max-width: 800px) {
      margin-bottom: 20px;
      padding: 0;
      margin-bottom: 10px;
    }

    &:hover {
        border-radius: 12px;
        background: rgba(207, 217, 217, 0.4);
        cursor: pointer;
        border-radius: 10px;

        @media (max-width: 800px) {
          opacity: 0.7;
        }
        @media (prefers-color-scheme: dark) {
          background: rgba(207, 217, 217, 0.2);
        }
    }
  `;

  return <LazyLoad>
    <BaseImage src={props.src}/>
  </LazyLoad>
}

const BoxedA = styled.a`
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  background: rgba(207, 217, 217, 0.5);
  @media (prefers-color-scheme: dark) {
    background: rgba(207, 217, 217, 0.2);
  }

  @media (max-width: 800px) {
    padding: 0;
    background: transparent;
    font-size: 0.8em;
  }

  &:hover {
      background: rgba(207, 217, 217, 0.9);
      cursor: pointer;

      @media (prefers-color-scheme: dark) {
        background: rgba(207, 217, 217, 0.3);
      }
  }
`;

const BoxedLink = styled(Link)`
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  background: rgba(207, 217, 217, 0.5);
  @media (prefers-color-scheme: dark) {
    background: rgba(207, 217, 217, 0.2);
  }
  @media (max-width: 800px) {
    padding: 0;
    background: transparent;
    font-size: 0.8em;
  }

  &:hover {
      background: rgba(207, 217, 217, 0.7);
      cursor: pointer;

      @media (prefers-color-scheme: dark) {
        background: rgba(207, 217, 217, 0.3);
      }
  }
`;

const LinkedInLink = styled(BoxedA)`
  margin-left: 30px;
  color: black;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  &:hover {
    color: steelblue;
  }
`;

const AboutLink = styled(BoxedLink)`
  color: black;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  &:hover {
    color: #2AC45E;
  }
`;

const DribbbleLink = styled(BoxedA)`
  margin-left: 30px;
  color: black;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  &:hover {
    color: salmon;
  }
`;

const LinkBox = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 800px) {
    margin-top: 10px;
  }
`;

class NavBar extends Component {

  state = {
    darkMode: false
  }

  componentDidMount() {
    if (window.matchMedia) {
      window.matchMedia('(prefers-color-scheme: dark)').addListener(({ matches }) => {
        this.setState({ darkMode: matches ? true : false });
      });
      if (window.matchMedia('(prefers-color-scheme: dark)').matches) {
        this.setState({ darkMode: true })
      }
    }
  }

  render() {
    const { showAboutLink, usePlain } = this.props;
    const logoSrc = usePlain ? (this.state.darkMode ? pcLogoPlainDark : pcLogoPlain) : 
                               (this.state.darkMode ? pcLogoDark : pcLogo)

    return (
      <Content>
        <Link to="/">
          <Logo src={logoSrc}/>
        </Link>
        <LinkBox>
          <AboutLink to='/about'>About</AboutLink>
          <LinkedInLink href='https://www.linkedin.com/in/punyasc'>LinkedIn</LinkedInLink>
          <DribbbleLink href='https://dribbble.com/punya'>Dribbble</DribbbleLink>
        </LinkBox>
      </Content>
    );
  }
}

export default NavBar;
