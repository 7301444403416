import React, { Component } from 'react';
import styled from 'styled-components';
import NavBar from '../Components/NavBar.jsx';
import { DefaultBlock } from '../Components/PageComponents.jsx';
import skillGraphSkilled from '../Assets/skillGraphSkilled.png';
import skillGraphUsed from '../Assets/skillGraphUsed.png';
import avatar from '../Assets/avatar.jpg';
import location from '../Assets/location.png';
import {
  HStack,
  ExtLink
} from '../Components/PageComponents.jsx';
import sweCompHeadOut from '../Assets/sweCompHeadOut.png';
import sweIconHeadOut from '../Assets/sweIconHeadOut.png';
import sweCompSolas from '../Assets/sweCompSolas.png';
import sweIconSolas from '../Assets/sweIconSolas.png';
import sweCompDayView from '../Assets/sweCompDayView.png';
import sweIconDayView from '../Assets/sweIconDayView.png';
import sweCompRhymePad from '../Assets/sweCompRhymePad.png';
import sweIconRhymePad from '../Assets/sweIconRhymePad.png';
import sweCompLaffy from '../Assets/sweCompLaffy.png';
import sweIconLaffy from '../Assets/sweIconLaffy.png';
import sweCompOddity from '../Assets/sweCompOddity.png';
import sweIconOddity from '../Assets/sweIconOddity.png';
import sweCompCritica from '../Assets/sweCompCritica.png';
import sweIconCritica from '../Assets/sweIconCritica.png';
import sweWorkATV from '../Assets/sweWorkATV.png';
import sweWorkAAA from '../Assets/sweWorkAAA.png';
import sweWorkIntel from '../Assets/sweWorkIntel.png';

const Container = styled.div`
  position: relative;
  width: 100%;

`;

const PaddedContainer = styled.div`
  max-width: 1100px;
  width: 80%;
  margin: 0 auto;
  flex-direction: row;
  display: flex;
  @media(max-width: 800px) {
    flex-direction: column;
  }
`;

const PrimaryContent = styled.div`
  flex-grow: 3;
  padding-bottom: 50px;
  @media(max-width: 800px) {
    width: 100%;
  }
`;

const AvatarImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50px;
`;

const Text = styled.p`
  margin-left: 10px;
  text-align: left;
`;

const StackText = styled.p`
  text-align: left;
  padding: 0;
  margin: 0;
  margin-left: 30px;
  @media (max-width: 800px) {
    margin: 0;
    margin-top: 20px;
  }
`;

const ImageTextStack = styled.div`
  display: flex;
  flex-direction: row;
  align-items: top;
  justify-content: flex-start;
  padding: 20px;
  border-radius: 20px;
  max-width: 720px;
  
  background: rgba(207, 217, 217, 0.5);
  @media (prefers-color-scheme: dark) {
    background: rgba(207, 217, 217, 0.2);
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Header = styled.h1`
  text-align: left;
  margin-top: 30px;
`;

const Subheader = styled.h2`
  text-align: left;
  margin-top: 80px;
  margin-bottom: 40px;
`;

const Footnote = styled.p`
  opacity: 0.5;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 10pt;
`;

function WorkLockup(props) {

  const Base = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    width: 100%;
    margin-bottom: 100px;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  `;

  const Comp = styled.img`
    width: 300px;
    height: 300px;

    @media (max-width: 1200px) {
      width: 200px;
      height: 200px;
    }

    @media (max-width: 800px) {
      width: 100%;
      height: auto;
    }
  `;

  const DetailStack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 40px;

    @media (max-width: 800px) {
      margin-left: 0;
      margin-top: 20px;
    }
  `;

  const Title = styled.h2`
    margin: 0;
    margin-top: 20px;
    text-align: left;
    color: black;
    @media (prefers-color-scheme: dark) {
      color: white;
      margin: 0;
    }
  `;

  const Caption = styled.p`
    opacity: 0.4;
    margin: 0;
    text-align: left;
    margin-top: 5px;
    color: black;
    @media (prefers-color-scheme: dark) {
      color: white;
    }
  `;

  const Description = styled.p`
    text-align: left;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 0;
    color: black;
    @media (prefers-color-scheme: dark) {
      color: white;
    }
  `;

  return <Base>
    <Comp src={props.compSrc}/>
    <DetailStack>
      <Title>{props.title}</Title>
      <Caption>{props.caption}</Caption>
      <Description>{props.description}</Description>
    </DetailStack>
  </Base>;
}

function ProjectLockup(props) {

  const Base = styled.div`
    display: flex;
    flex-direction: row;
    align-items: top;
    width: 100%;
    margin-bottom: 100px;

    @media (max-width: 800px) {
      flex-direction: column;
    }
  `;

  const Comp = styled.img`
    width: 600px;
    height: 450px;

    @media (max-width: 1200px) {
      width: 400px;
      height: 300px;
    }

    @media (max-width: 800px) {
      width: 100%;
      height: auto;
    }

    &:hover {
      opacity: 0.7;
    }

    transition: opacity .25s ease-in-out;
    -moz-transition: opacity .25s ease-in-out;
    -webkit-transition: opacity .25s ease-in-out;
  `;

  const DetailStack = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 40px;

    @media (max-width: 800px) {
      margin-left: 0;
      margin-top: 20px;
    }
  `;

  const Icon = styled.img`
    width: auto;
    height: 75px;
  `;

  const Title = styled.h2`
    margin: 0;
    margin-top: 20px;
    text-align: left;
    color: black;
    @media (prefers-color-scheme: dark) {
      color: white;
    }
  `;

  const Caption = styled.p`
    opacity: 0.4;
    margin: 0;
    text-align: left;
    margin-top: 5px;
    color: black;
    @media (prefers-color-scheme: dark) {
      color: white;
    }
  `;

  const Description = styled.p`
    text-align: left;
    margin: 0;
    margin-top: 8px;
    margin-bottom: 0;
    color: black;
    @media (prefers-color-scheme: dark) {
      color: white;
    }
  `;

  const PrimaryLink = styled.a`
    font-weight: bold;
    margin-top: 20px;
    text-align: left;

    &:hover {
      color: dodgerblue;
    }
  `;

  return <Base>
    <a href={props.primaryLink}>
      <Comp src={props.compSrc}/>
    </a>
    <DetailStack>
      <Icon src={props.iconSrc}/>
      <Title>{props.title}</Title>
      <Caption>{props.caption}</Caption>
      <Description>{props.description}</Description>
      <PrimaryLink href={props.primaryLink}>{props.primaryLinkTitle}</PrimaryLink>
    </DetailStack>
  </Base>;
}

class SoftwareScreen extends Component {

  onKeydown(event) {
    if (event.keyCode === 27) {
      const { onDismissed } = this.props;
      if (onDismissed) {
        onDismissed()
      }
    }
  }

  constructor(props) {
    super(props);
    this.onKeydown = this.onKeydown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeydown);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Punya | Software Portfolio';
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeydown);
  }

  render() {
    const { present } = this.props;

    return (
      <Container present visible>

        <DefaultBlock>
          <NavBar usePlain={true}/>
        </DefaultBlock>
        <PaddedContainer>
          <PrimaryContent>
            <Header>Software Projects</Header>
            <ImageTextStack>
              <AvatarImage src={avatar}/>
              <StackText>
                I'm Punya. I graduated from <b>Stanford University in 2019</b> with a BS in Computer Science (focusing on Human-Computer Interaction), and I work as a <b>UI Design Prototyper at Apple</b> in Cupertino. I am also an <ExtLink href='http://punyavisuals.com'>independent filmmaker</ExtLink>. Prior to joining Apple, I made apps for the iOS and tvOS App Stores, handling both design and development.
              </StackText>
            </ImageTextStack>

            <Subheader>Apps on the App Store</Subheader>
            <ProjectLockup
              compSrc={sweCompDayView}
              iconSrc={sweIconDayView}
              title='DayView'
              caption='2018-2019, tvOS'
              description="Gives you all the info you need to start your day, at a single glance on your Apple TV. Stayed as the #1 Productivity app on the tvOS App Store from October 2018 to March 2019."
              primaryLink='https://apps.apple.com/us/app/dayview-personal-dashboard/id1441079898'
              primaryLinkTitle='Download on the App Store'
            />
            <ProjectLockup
              compSrc={sweCompRhymePad}
              iconSrc={sweIconRhymePad}
              title='RhymePad'
              caption='2017-2018, iOS'
              description="A songwriter's toolbox, which helps you write songs by giving you rhyming suggestions, letting you find instrumentals, and letting you put it all together and record demos. Uses DataMuse API for rhyme data and YouTube API for instrumental content."
              primaryLink='https://apps.apple.com/au/app/rhymepad-a-rappers-toolkit/id1292510688'
              primaryLinkTitle='Download on the App Store'
            />
            <ProjectLockup
              compSrc={sweCompHeadOut}
              iconSrc={sweIconHeadOut}
              title='HeadOut'
              caption='2018, iOS'
              description="Lets you quickly figure out how long it'll take you to get to your favorite places. Uses MapKit for search and calculating ETAs."
              primaryLink='https://apps.apple.com/us/app/headout/id1353684013'
              primaryLinkTitle='Download on the App Store'
            />
            <ProjectLockup
              compSrc={sweCompOddity}
              iconSrc={sweIconOddity}
              title='Oddity'
              caption='2017, iOS'
              description="Lets you play a remote game of 'What Are The Odds?', where you challenge friends to a bet. Uses Firebase database to store data and push live updates."
              primaryLink='https://apps.apple.com/us/app/oddity-what-are-the-odds/id1330210847'
              primaryLinkTitle='Download on the App Store'
            />

            <Subheader>Prototypes (made for my academic projects)</Subheader>
            <ProjectLockup
              compSrc={sweCompCritica}
              iconSrc={sweIconCritica}
              title='Critica'
              caption='2019, Web'
              description="An online platform for musicians and other creatives to get helpful constructive criticism on their projects. This was my Stanford Computer Science BS capstone project. Uses React with Styled-Components for UI."
              primaryLink='https://dribbble.com/shots/9075765-Critica-Web-App'
              primaryLinkTitle='View on Dribbble'
            />
            <ProjectLockup
              compSrc={sweCompLaffy}
              iconSrc={sweIconLaffy}
              title='Laffy'
              caption='2018, iOS'
              description="Giving stand-up comedy fans a unified platform to discover online and live content. Uses Firebase for analytics and YouTube API for content."
              primaryLink='https://punyachatterjee.com/#/laffy'
              primaryLinkTitle='Read full case study'
            />
            <ProjectLockup
              compSrc={sweCompSolas}
              iconSrc={sweIconSolas}
              title='Solas'
              caption='2017, iOS'
              description="Helping people moving into a new city make new friends and find places to go. Uses Yelp API for place data, and MapKit for the map UI."
              primaryLink='https://punyachatterjee.com/#/solas'
              primaryLinkTitle='Read full case study'
            />

            <Subheader>Work Experience</Subheader>
            <WorkLockup
              compSrc={sweWorkATV}
              title='Apple | UI Engineer'
              caption='Jul 2019 - Present'
              description="Building UIs for a new native app for tvOS, and contributing to SwiftUI for tvOS."
            />
            <WorkLockup
              compSrc={sweWorkAAA}
              title='Apple | iOS Intern'
              caption='Jun - Sep 2018'
              description="Built a minor system notification feature for iOS 13 for the Accounts and Authentication team, and converted internal tools to Swift."
            />
            <WorkLockup
              compSrc={sweWorkIntel}
              title='Intel | Software Intern'
              caption='Jun - Sep 2016'
              description="Maintained an internal site for the system-on-chip division, and configured environments for development kits."
            />
            <ExtLink href='https://github.com/punyasc'>GitHub</ExtLink>
            <Footnote>(This site was designed and developed by me as well)</Footnote>
          </PrimaryContent>
        </PaddedContainer>
      </Container>
    );
  }
}

export default SoftwareScreen;
