import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  BannerImage,
  HaitchOne,
  TextStack,
  HStack,
  HStackChild,
  HStackTripleChild,
  Image,
  SplitImage,
  HalfImage,
  InvertableImage,
  Cframe,
  Protoframe,
  Comment,
  BackText,
  DefaultBlock,
  ProConGrid,
  CaptionStack
} from '../Components/PageComponents.jsx';
import laffyHero from '../Assets/laffyHero.png';
import laffyBrainstorm from '../Assets/LaffyBrainstorm.jpg';
import laffyFindings from '../Assets/LaffyFindings.png';
import laffySketches from '../Assets/LaffySketches.png';
import laffyFlow1 from '../Assets/LaffyFlow1.png';
import laffyFlow2 from '../Assets/LaffyFlow2.png';
import laffyFlow3 from '../Assets/LaffyFlow3.png';
import laffyAnalytics from '../Assets/LaffyAnalytics.jpg';
import laffyCompAll from '../Assets/LaffyCompAll.png';
import laffyCompBrowse from '../Assets/LaffyCompBrowse.png';
import laffyCompDiscover from '../Assets/LaffyCompDiscover.png';
import laffyCompLive from '../Assets/LaffyCompLive.png';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import xcode from '../Assets/Tools/xcode.png';
import sketch from '../Assets/Tools/sketch.png';
import firebase from '../Assets/Tools/firebase.png';
import styled from 'styled-components';

const H1 = styled.h1`
  color: #00c47c;
`;

class DayViewScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Laffy | Case Study';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <BannerImage src={laffyHero}/>
        <PaddedBlock>
          <HaitchOne>Laffy</HaitchOne>
          <p>Laffy is an app that serves as a stand-up comedy discovery concierge, helping comedy fans easily find online content and live shows.</p>
          <p>This was my project for my “Design Studio: Understanding Users” class at Stanford, which lasted from March to June 2018. I worked on a team of four, with each of us <b>evenly dividing the user research and development work</b>. I primarily <b>led the visual and interaction design</b> of the project, from the wireframing stages to the final developed app.</p>
          <br/>
          <ProjectOverview
            finalProduct='iOS app, high-fidelity prototype'
            duration='10 weeks'
            toolsUsed={[sketch, xcode, firebase]}
            teamMembers='Crystal Escolero, Olivia Gregory, Scott Buckstaff'
            myRole='User researcher, lead visual designer, developer'
            methodsUsed='Competitive/literature review, usability testing, app analytics'
            accentColor='#00c47c'
          />


          <H1>Overview</H1>

          <h2>The Problem</h2>
          <p>While viewership of stand-up comedy content has increased, partly thanks to streaming services like Netflix and HBO producing stand-up content, many comedy fans struggle to find new content to watch online or find live comedy shows near them. Additionally, some people want to watch stand-up comedy but have no idea where to start looking. </p>
          <br/>

          <h2>Our Solution</h2>
          <p>We prototyped an iOS app called Laffy that makes it easy to interact with stand-up comedy, both online and live content. It aggregates online content from various sources and presents you with recommendations, so you can find the right clip or show to watch.
            It also tells you when comedians you like are performing near you.</p>
          <br/>

          <h2>How It Works</h2>
          <br/>
          <HStack>
            <HStackChild>
              <Image src={laffyCompBrowse}/>
            </HStackChild>
            <HStackChild>
              <CaptionStack>
                <h3>Browse Clips</h3>
                <p>The Browse tab aggregates online content from various sources and presents you with personalized recommendations.</p>
              </CaptionStack>
            </HStackChild>
          </HStack>
          <br/>
          <br/>
          <HStack>
            <HStackChild>
            <CaptionStack>
              <h3>Discover Comedians</h3>
              <p>The Discover tab lets you quickly find a comedy show to watch by answering short multiple-choice questions.</p>
            </CaptionStack>
            </HStackChild>
            <HStackChild>
            <Image src={laffyCompDiscover}/>
            </HStackChild>
          </HStack>
          <br/>
          <br/>
          <HStack>
            <HStackChild>
              <Image src={laffyCompLive}/>
            </HStackChild>
            <HStackChild>
              <CaptionStack>
                <h3>Find Live Shows</h3>
                <p>The Live tab tells you when the comedians you watch (and comedians you may like) are performing near you.</p>
              </CaptionStack>
            </HStackChild>
          </HStack>
          <br/>


          <H1>Research</H1>

          <p>To cover all bases, we started our research into the project by conducting a literature review, a competitive review, and user interviews.</p>
          <p>The questions we sought out to answer through our research were:</p>
          <ul><li>What platforms and methods do people use to discover stand up comedy?
          </li><li>Do people need a dedicated stand-up comedy platform? Is it a big enough market to sustain its own platform?
          </li><li>What demographics of people are using what platforms, and why?
          </li><li>How does the real-life experience of going to live shows manifest itself in the online comedy platforms that exist today? How do users decide to go to live shows?
          </li></ul>
          <br/>

          <h2>Literature Review</h2>
          <p>For our literature review, we read a number of academic journals that touched on the emergence of stand-up comedy in online platforms and how its consumption has increased and transformed in recent years, to help us understand the industry a little better.</p>
          <h4>Our key takeaways were:</h4>
          <ul><li>Comedians are readily embracing online platforms to reach new audiences
          </li><li>Comedians view stand-up shows as stepping stones for them to get streaming series or films with companies like Netflix
          </li><li>Marketing through social media is becoming increasingly more important than traditional advertising or publicity
          </li><li>While the music industry has seen services that bridge the gap between independently- and professionally-made content, like Spotify and Soundcloud which help independent artists, there is no such service for the comedy industry
          </li></ul>
          <br/>

          <h2>Competitive Review</h2>
          <p>Next, we conducted our competitive review, where we evaluated the effectiveness of three different comedy discovery platforms: Netflix, Spotify, and <a href="http://LookLaugh.com">LookLaugh.com</a>.</p>
          <p>Netflix is a significant contender because of its large user base and the number of original stand-up comedy shows it produces. Spotify leads in the audio-only consumption of stand-up comedy, with thousands of audio specials available on the platform. <a href="http://LookLaugh.com">LookLaugh.com</a> is a less-known platform that is purely dedicated to stand-up comedy, partly acting like an IMDb for comedians. We evaluated each one on their satisfaction of user needs, their interaction design, and their visual design. </p>
          <h4>Our key takeaways were:</h4>
          <ul>
            <li>Netflix’s large library of content makes it difficult to decide on a single piece</li>
            <li>Spotify’s search function lacks filtering or sorting, making it harder to find subcategories of comedy</li>
            <li>LookLaugh.com's comedian directory makes it easy to find out more about comedians, but lacks polish</li>
          </ul>
          <br/>

          <h2>User Interviews</h2>
          <p>Finally, we wanted to interview some people who like watching stand-up comedy to see what challenges they face with content discovery. To find participants, we sent a screener survey to a number of students and alumni, which asked them questions about their comedy viewing habits. We selected five people for interviews, all of whom enjoy watching stand-up comedy to various degrees.</p>
          <h4>We asked them:</h4>
          <ul>
            <li>When was the last time you watched stand up comedy?
            </li><li>What motivates you to watch a certain show?
            </li><li>What mediums have you used to listen to or watch comedy?
            </li><li>Tell us about a time you wanted to watch some comedy but struggled to find something
            </li>
          </ul>
          <br/>
          <blockquote>
            <p>“I would use [a platform] that would help me find more comedians I actually like”</p>
            <footer>Noah, <cite>Software Engineer, 22</cite></footer>
          </blockquote>
          <p>Noah is an occasional comedy watcher. He wishes he could easily discover comedians and opportunities to see them live.  He has attended a couple of small comedy shows near him, but does not know where to look for professional ones. He occasionally gets recommended comedians from his friends, but has trouble finding their content afterward. </p>
          <br/>

          <blockquote>
            <p>“I enjoy stand-up, but only watch it with my friends because I don’t know who I like to watch”</p>
            <footer>Jake, <cite> Analyst, 26</cite></footer>
          </blockquote>
          <p>Jake likes to listen to stand-up specials occasionally when he is working. On weekends, he tries to find stand-up specials on Netflix, but often finds himself searching for 20 minutes and resorting to something he’s already watched. He says he’s rewatched more comedy shows than seen new ones, due to his limited knowledge of comedians.</p>
          <br/>

          <blockquote>
            <p>“I love comedy. With all the stress at work, comedy just makes life easier”</p>
            <footer>Jack, <cite>Product Manager, 26</cite></footer>
          </blockquote>
          <p>Jack is an avid stand-up comedy fan who frequently listens to stand-up comedy podcasts and occasionally attends live comedy shows in Hollywood. He mostly discovers new comedians by going to live shows, while using platforms like Netflix to find content for comedians he already knows. Having some friends in the comedy industry, he wishes he could support independent comedians more.</p>
          <br/>

          <blockquote>
            <p>“I aimlessly browse through comedy specials and try different things until something sticks. It’s not the fastest method, and I end up watching a lot of crap”</p>
            <footer>Sara, <cite>Chemical Engineer, 21</cite></footer>
          </blockquote>
          <p>Sara loves watching stand-up comedy specials live and online, but struggles to find “the right” shows, instead having to resort to a random approach. She knows there is plenty of live and online comedy she would enjoy, but she doesn’t know how to find shows that fit her sense of humor. Her favorite comedians are the ones shown to her by friends.</p>
          <br/>

          <blockquote>
            <p>“I wish I could just easily watch a funny routine when I feel like it”</p>
            <footer>Travis, <cite> student, 21</cite></footer>
          </blockquote>
          <p>Travis is a comedy fan who wishes he could rediscover comedians have shown him more easily. He also wishes for more ways to solicit comedian recommendations from his friends. He’ll often be trying to find something to watch, but fail to remember the name of a comedian someone recommended to him.</p>
          <br/>

          <p><Image src={laffyBrainstorm}/></p>
          <p>We jotted down everyone’s thoughts onto post-it notes, then used affinity mapping to find the most pressing challenges our participants faced:</p>
          <br/>
          <p><Image src={laffyFindings}/></p>
          <br/>


          <H1>Interaction Design</H1>
          <p>After an initial team brainstorm, I drew these wireframes for the different screens of the app:</p>
          <p><InvertableImage src={laffySketches}/></p>
          <br/>
          <p>For our initial usability test, we wanted to create a paper prototype that users would interact with while thinking aloud, so we refined the wireframes further and drew them onto index cards that we could swap out during testing. We conducted user tests during our class period, and we had members of various other groups participate in our tests.</p>
          <br/>
          <h4>We gave them these tasks:</h4>
          <ol start="1"><li>Search for a comedian and see their profile (we phrased it: “Find some information about the comedian Kevin Hart”)
          </li></ol>
          <p><InvertableImage src={laffyFlow1}/></p>
          <ol start="2"><li>Decide on a show to watch (we phrased it: “You’re alone and feel like watching a comedy show, but don’t know what to watch. Use our platform to find something quickly”)
          </li></ol>
          <p><InvertableImage src={laffyFlow2}/></p>
          <ol start="3"><li>Find a live show (we phrased it: “You feel like seeing a comedy show in person this weekend. How would you find a show and get tickets?”)
          </li></ol>
          <p><InvertableImage src={laffyFlow3}/></p>
          <br/>
          <h4>Our key findings from these tests:</h4>
          <ul><li>The meaning of "shows coming up” on a comedian’s profile was confusing, so we changed it to be “Upcoming Live Shows.” If one of these shows was near the user’s current location, it would be listed first, followed by their other shows
          </li><li>People who used the Discover tab felt like they still had more work to do after being presented with comedian options, so we added an option to preview each comedian’s content before leaving the Discovery flow
          </li><li>The prompts in the Discovery tab had some personality to them, which users liked but only in limited quantities, so we dialed it back for our next iteration
          </li><li>People wanted more clarity on the organization of content on the Live tab, so we decided to combine the different sub-headers and instead present a linear list with sorting options
          </li></ul>
          <br/>


          <H1>Prototyping</H1>
          <Image src={laffyCompAll}/>
          <br/>
          <p>Due to the rapid pace of this course and the focus on user testing, we went straight from the low-fidelity prototype to building a high-fidelity prototype iOS app. This app would cover only the most basic use cases planned so that we could perform analytics and other basic tests on it. </p>
          <p>Since I had designed the wireframes, I also led the design of the iOS app, making sure it was still visually appealing while we rushed to get basic functionality running. Olivia and I developed the Browse tab, Scott and Crystal developed the Live tab, and I developed the Discover tab. A vital goal of this project was to collect analytic data for testing, so we integrated Firebase Analytics to track impressions on each view.</p>
          <br/>


          <H1>Evaluation</H1>
          <p>With the prototype built, we conducted a field study to evaluate it. We selected participants by sending another screener to several students and alumni, picking ten who seemed most likely to participate in the full duration of the study. The participants had diverse backgrounds, an even gender split, and within an age range of 18-29.</p>
          <p>We instructed users to keep the app on their phone for ten days and use the voice memos app to think aloud whenever they use it (as a diary study), encouraging them to use it at times when they would naturally want to watch comedy content. Our integration with Firebase Analytics helped us see which parts of the app were most engaging.</p>
          <p><HalfImage src={laffyAnalytics}/></p>
          <p>We found that the Browse tab was the most popular, accounting for 55% of total page impressions.</p>
          <p>Something that surprised us from our participants’ feedback is how much they loved the Live view. They remarked that they had not seen live show information like this presented to them in any other popular app they use, and having it right alongside the other browsable online content made it very compelling.</p>
          <br/>
          <h4>Standout findings from the study:</h4>
          <ul>
            <li>There’s a need more dynamic data on the Browse page, since it would be so heavily used (for this MVP, the suggestions were merely hardcoded)
            </li><li>The Discover tab needs more tuning and better recommendations, which would be possible if we had a real back-end utilizing machine learning
            </li><li>People have a desire for more social interaction - they wish to show things to friends or see which of their friends were going to a live show. They also wish they could also comment or tag videos at particular points before sharing them
            </li>
          </ul>
          <br/>

          <h2>Future Goals</h2>
          <h4>If we were able to develop this project further, we would like to make these changes:</h4>
          <ul><li>Integrate "live" more in other aspects
          <ul><li>  Notify users about live shows near them after they watch an online show
          </li><li> Possibly include live shows (or just hints of them) on the Browse page
          </li><li> Highlight live shows even more on comedians’ pages
          </li></ul></li><li>Create a real back-end that makes use of machine learning to provide actual content recommendations
          </li><li>If possible, aggregate comedy content from more platforms. For this MVP, we were only able to show Youtube content since YouTube was the only streaming platform with an open API. It would be ideal to integrate Netflix, HBO, and more
          </li><li>Add basic sharing options so users can share clips and shows outside of the app, and implement some sort of social network integration so friends can recommend content within the app
          </li></ul>
          <br/>


          <H1>Reflection</H1>
          <p>This project had a much greater focus on testing and analysis than my earlier project, Solas, which focused more on design iteration. As a result, I felt like this project strengthened my skills in evaluating designs and synthesizing research from many different sources. The pacing of the course also allowed me to practice rapid visual design, since we were expected to create an MVP with fewer iterative steps building up to it.</p>
          <p>While we were not able to pursue this idea further after the class, I’m satisfied with what we came up with, especially because some of the design choices we made ended up being validated by some changes Netflix and the Apple TV app made after this project concluded.</p>


          <Link to='/'>
            <BackText>Back</BackText>
          </Link>
        </PaddedBlock>
  </PageContainer>
    );
  }
}

export default DayViewScreen;
