import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  BannerImage,
  HaitchOne,
  TextStack,
  HStack,
  HStackChild,
  Image,
  SplitImage,
  HalfImage,
  InvertableImage,
  Cframe,
  Comment,
  BackText,
  DefaultBlock
} from '../Components/PageComponents.jsx';
import solasHero from '../Assets/solasHero.png';
import dayviewThumbnail from '../Assets/dayviewThumbnail.png';
import solasNotes1 from '../Assets/SolasNotes1.jpg';
import solasNotes2 from '../Assets/SolasNotes2.jpg';
import solasNotes3 from '../Assets/SolasNotes3.jpg';
import solasSketchConcierge from '../Assets/SolasSketchConcierge.jpg';
import solasSketchListMap from '../Assets/SolasSketchListMap.jpg';
import solasTaskDiscover from '../Assets/SolasTaskDiscover.jpg';
import solasTaskFind from '../Assets/SolasTaskFind.jpg';
import solasTaskContribute from '../Assets/SolasTaskContribute.jpg';
import solasMfiChangesCheckin from '../Assets/SolasMfiChangesCheckin.jpg';
import solasMfiChangesRemoval from '../Assets/SolasMfiChangesRemoval.jpg';
import solasMfiChangesMap from '../Assets/SolasMfiChangesMap.jpg';
import solasCompFind from '../Assets/SolasCompFind.png';
import solasCompDiscover from '../Assets/SolasCompDiscover.png';
import solasCompContribute from '../Assets/SolasCompContribute.png';
import solasAllComps from '../Assets/SolasAllComps.png';
import solasAppIcon from '../Assets/solasAppIcon.png';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import photoshop from '../Assets/Tools/photoshop.png';
import marvel from '../Assets/Tools/marvel.png';
import sketch from '../Assets/Tools/sketch.png';
import xcode from '../Assets/Tools/xcode.png';
import styled from 'styled-components';

const H1 = styled.h1`
  color: #3a76d6;
`;

class SolasScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Solas | Case Study';
  }

  render() {
    return (
      <PageContainer>
        <DefaultBlock>
          <NavBar usePlain={true}/>
        </DefaultBlock>
        <BannerImage src={solasHero}/>
        <PaddedBlock>
          <HaitchOne>Solas</HaitchOne>
          <p>Solas is an app that helps people find popular places to hang out, meet friends in those places, and easily contribute to a crowd-sourced network of information.</p>
          <p>I worked on a team of four, with each of us <b>evenly dividing the user research work</b> (conducting interviews and testing our prototypes). <b>I led the visual design</b> during the lo-fi and medium-fi prototype stages, and I was the <b>primary developer</b> of the final high-fi prototype.</p>
          <br/>
          <ProjectOverview
            finalProduct='iOS app, high-fidelity prototype'
            duration='10 weeks'
            toolsUsed={[sketch, marvel, photoshop, xcode]}
            teamMembers='Chloe Rosen, Sayuri Sekimitsu, Sydney Hutton'
            myRole='User researcher, lead visual designer, lead developer'
            methodsUsed='User interview, experience prototypes, wireframing, development'
            accentColor='#3a76d6'
          />
          

          <H1>Overview</H1>
          <h2>The Problem</h2>
          <p>Young adults moving to new cities often struggle to build a community in their new location. This especially applies to recent college graduates, who often move to cities that are completely new to them post-graduation. Moving to a new place can be scary, and meeting new people and finding places to go makes it even more difficult.</p>
          <br/>
          <h2>Our Solution</h2>
          <p>We created an iOS app called Solas that makes finding new places exciting. It recommends places where people who share your interests go, or where your friends or friends of friends have gone before. You can use Solas to check-in to a cool place you're at so your friends will know you're there, and so others in the Solas network who share your interests can know to go there.
          </p>
        
          <br/>

          <h2>How It Works</h2>
          <p>The app has three primary use cases: Find, Discover, and Contribute.</p>
          <br/>
          <h3>Find</h3>
          <p>A heatmap is displayed on the home screen to indicate where the most interesting places around you are. By clicking the “friends view” button, you can see where your Facebook friends have checked in.
          </p>
          <Image src={solasCompFind}/>
          <br/>
          <br/>
          
          <h3>Discover</h3>
          <p>The Search and Hot Now tabs let you discover places to go. The Hot Now tab presents a curated list of places to visit, while the Search tab lets you enter filters to get customized suggestions.
          </p>
          <Image src={solasCompDiscover}/>
          <br/>
          <br/>
          <h3>Contribute</h3>
          <p>When you get to a place you like, you can check-in there to share it with your friends. Your location will now show up as a pin on your friends' maps. When you leave that place, press "check out" to remove it.
          </p>
          <Image src={solasCompContribute}/>
          <br/>


          <H1>User Research</H1>
          
          <h2>To start investigating the problem, we interviewed four recent college graduates.</h2>
          <p>Each of us found two recent bachelor's or master's graduates of different backgrounds to interview individually. We wanted to understand how social concerns played a role in where they moved to post-graduation.</p>
          <h4>Questions we asked:</h4>
          <ul><li>How many new people have you met since moving? How have you met them?
          </li><li>Can you tell us a story about a time you met someone new?
          </li><li>Have you used technology to meet people?
          </li><li>Tell me a story of when you felt uncomfortable or lonely after moving. What did you do to feel better?
          </li></ul>
          <h4>Thoughts we heard:</h4>
          <blockquote>
          <p>“Common interests and a sense of what’s important to you can change in your early 20’s.”</p>
          <footer>Aliza, <cite>22 year old Stanford alum working in LA</cite></footer>
          </blockquote>
          <blockquote>
          <p>“After graduation, I sought out a place with friends. I could find a job anywhere, but being close to friends makes getting to know a city so much more fun.”</p>
          <footer>Ellis, <cite>23 year old Darmouth alum working in Boston</cite></footer>
          </blockquote>
          <br/>
          
          <h2>Next, we listed out all of their thoughts and concerns and used affinity mapping to sort them.</h2>
          <HStack>
            <HStackChild>
              <Image src={solasNotes1}/>
            </HStackChild>
            <HStackChild>
              <Image src={solasNotes2}/>
            </HStackChild>
          </HStack>
          <p>
          This showed us the six most salient issues our interviewees had, from which we came up with three questions we wanted to answer.</p>
          <h4>How might we…</h4>
          <ul>
            <li><b>help people form enduring friendships</b> after they've made an initial connection?
            </li><li><b>use our existing social networks</b> to meet people?
            </li><li><b>identify social places</b> for young people to interact?
            </li>
          </ul>
          <br/>
          <h2>We then brainstormed a list of solutions for each question.</h2>
          <p>
            <Image src={solasNotes3}/>
          </p>
          <p>We made simple experience prototypes for our two favorite concepts and tested them with four new people.
            One was a simple Google Form that asked participants how likely they would be to socially interact with “friends of friends” whom they’ve never met. The other involved two participants writing down places they like to visit until they found multiple places in common. 
          </p>
          <h4>Our takeaways from the experience prototypes:</h4>
          <ol>
            <li>Locations can be a good indicator of compatibility only when combined with other factors, like hobbies
            </li><li>If someone is in high social need (e.g., knowing no one in a new city), they are more open to meeting with “friends of friends”
            </li>
          </ol>
          <br/>


          <H1>Interaction Design</H1>

          <p>Based on our takeaways, we decided to create an iOS app that recommends locations to visit based on your interests and the interests of friends in your social network.</p>
          <h2>After a brainstorm session, we found two possible flows to decide between.</h2>
          <TextStack>
            <h3>Option 1: Concierge</h3>
            <SplitImage src={solasSketchConcierge}/>
            <p>With this flow, the user would be greeted by a linear flow of options, much like an onboarding. Our system would then use the user’s input to provide a recommendation.</p>
            <ul>
              <h4>Pros</h4>
              <li>Presents curated content
              </li><li>Less mental burden
              </li><li>Easier for user
              </li>
              <br/>
              <h4>Cons</h4>
              <li>Loss of control
              </li><li>No direct exploration
              </li><li>Too many steps for quick use
              </li>
            </ul>
          </TextStack>
          <TextStack>
            <h3>Option 2: List/Map</h3>
            <SplitImage src={solasSketchListMap}/>
            <p>This flow is more like a traditional location suggestion service like Yelp or Google Maps, where the user can alternate between a list view of locations and a map view. A notable addition to our map would be a heat map-like overlay that indicates the most popular locations where people have checked in.</p>
            <ul>
              <h4>Pros</h4>
              <li>Visually appealing
              </li><li>List is intuitive and specific
              </li><li>Gives user freedom
              </li>
              <br/>
              <h4>Cons</h4>
              <li>Many transitions and views
              </li><li>Slightly confusing
              </li><li>Too many steps for quick use
              </li>
            </ul>
          </TextStack>

          <h2>We decided to go with the List/Map flow for our Low&#8209;Fidelity Prototype</h2>
          <p>I re-drew the List/Map wireframes to be cleaner and more detailed, and we determined three major tasks we wanted our users to accomplish with our app:</p>
          <ol start="1"><li><b>Discover</b> places to go using the List view
          </li></ol>
          <p><InvertableImage src={solasTaskDiscover}/></p>
          <br/>
          <ol start="2"><li><b>Find</b> your friends using the Map view
          </li></ol>
          <p><InvertableImage src={solasTaskFind}/></p>
          <br/>
          <ol start="3"><li><b>Contribute</b> by "checking in” to a place
          </li></ol>
          <p><InvertableImage src={solasTaskContribute}/></p>
          <br/>
          <p>These three tasks were the basis for the three major use cases of the app: Discover, Find, and Contribute.</p>
          <br/>

          <h2>We conducted user testing with paper prototypes and these three tasks.</h2>
          <p>We printed and cut out all of the UI mockups to create a paper prototype, which the participants would interact with while thinking aloud. We found four new participants, all of whom were senior undergraduate students.</p>
          <h4>Our key takeaways: </h4>
          <ul><li>People did much better when presented with mobile navigation patterns they were familiar with, so we should rely on those to reduce cognitive load
          </li><li>Our topic requires people to share personal information, so we need to be transparent about where that information is going in order to establish trust
          </li></ul>
          <h4>The resulting changes we made for our medium-fi prototype:</h4>
          <ul><li>Increase clarity when “checking in” to a location by changing the Public/Private toggle to a “Tell My Friends” toggle
          </li><li>Remove the functionality of planning future check-ins to prevent confusion
          </li><li>Simplify the maps interface by adopting a bottom tab bar instead of using floating buttons.
          </li></ul>
          <br/>


          <H1>Visual Design</H1>

          <h2>Next, I made detailed mockups of each screen using Sketch.</h2>
          <p><Image src={solasAllComps}/></p>
          <p>We wanted to build a simple interactive prototype that would help us tweak our flows and figure out the final visuals, so I first created Sketch mockups of every view in our wireframes.</p>
           <p>This step was particularly fun because it was my first experience using Sketch, and it proved to be a valuable tool for me to generate these mockups from our drawings quickly. I created the heat map overlay images in Photoshop.</p>
          <br/>

          <HalfImage src={solasAppIcon}/>
          <p>I also used Sketch to create this icon which would be used both in the iOS app icon and alongside the service's logotype. The blue dot surrounded by a circle is meant to evoke the "current location" marker that appears in most map apps, while the bordering arrows evoke imagery of a sun (a play on the name Solas) as well as motion in all directions. The slightly pale blue color is meant to evoke a sense of calmness and trust, which we viewed as important values for a friend-making app.</p>
          <br/>


          <H1>Prototyping</H1>

          <h2>With the visual mockups complete, we brought them into Marvel to build our medium-fidelity prototype.
          </h2>

          <Cframe src="https://marvelapp.com/44bd8b9?emb=1&iosapp=false&frameless=false" width="453" height="920" allowTransparency="true" frameBorder="0">
          </Cframe>
          <p>We then had classmates perform heuristic evaluations using this prototype. We picked the most pressing problems, then found solutions to implement in our high-fidelity (coded) prototype.</p>
          <h4>Some of the changes we made:</h4>
          <ol>
            <li>The screen was overcrowded when many friends were displayed, so we lowered the opacity of the heat maps and resorted to using small pins to indicate where friends are
            </li>
            <li>The symbols in the Hot Now view were not self-explanatory, so we redesigned the list view to show images from the locations instead
            </li>
            <li>Searching for places using a modal was unintuitive and confusing, so we added it to the map’s navigation bar
            </li>
            <li>It was not easy to undo check-ins, so we added a “Check Out” button to the map view to let the user avoid going into their list of check-ins
            </li>
          </ol>
          <br/>

          <h2>Finally, we developed an iOS app for our final prototype.</h2>
          <p>A majority of the flows from our previous prototypes were improved and included, while we omitted some flows like onboarding and user settings customization due to time constraints.</p>
          <p>I was the sole developer of the high-fidelity prototype, which I created in Xcode using Swift. The level of detail we had gone into for our interactive prototype made it easy to build the app quickly, since many of the variables had been figured out. We used the Yelp Fusion API to provide us with real location data based on the user’s location, and the Firebase Database API to store user information. </p>
          <br/>


          <H1>Evaluation & Reflection</H1>

          <p>We evaluated our prototype by participating in our class’s project expo, where other students and invited industry members demoed our app and discussed our process. From that experience, we gained a great amount of useful feedback on how our solution could be explored further.</p>
          <p>Given an opportunity to work on this project further, we would have loved to add these features:</p>
          <ul>
            <li>Add the ability to connect with Facebook to view friends’ and mutual friends’ favorite places and suggestions
            </li><li>Create a profile page for each user, with information about their previous check-ins and user pattern behavior
            </li><li>Include an introductory walkthrough to help those new to the app
            </li><li>Add more options for filters, like “Popular with People My Age” or “Popular with People In My Industry”
            </li>
          </ul>
          <br/>
          <p>Working on this project was my first time getting to conduct the entire design process, from defining a problem to creating a usable application, so it helped me gain a great amount of perspective on how the various stages of the design process work. The skills I developed while working on this project have been useful to me in every subsequent project I’ve worked on. </p>
          <p>The timing of this app was a little unfortunate, especially because Snapchat unveiled a similar “friends heat map” feature soon after, and the Facebook Graph API became more limited, making some of our features no longer possible. However, I’m still proud of the solution we came to because it presented a holistic answer to the variety of problems young adults face when moving to new places, and I feel that it fills that niche more than some of the competing location-recommendation services do.</p>
          <Link to='/'>
            <BackText>Back</BackText>
          </Link>
        </PaddedBlock>
      </PageContainer>
    );
  }
}

export default SolasScreen;
