import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';

const Content = styled.div`

  margin-bottom: 60px;

  @media (max-width: 1200px) {
    width: auto;
    margin-bottom: 20px;
  }

  @media (max-width: 800px) {
    width: auto;
  }
`;

const Title = styled.h1`
  font-size: 20pt;
  margin-top: 0;
  margin-bottom: 20px;
  opacity: 0.3;
`;

const Description = styled.p`
  margin-top: 0;
`;

const ItemGrid = styled.div`
  display: grid;
  column-gap: 50px;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr 1fr 1fr;

  @media (max-width: 800px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
`;


const Item = styled.div`
  text-align: left;
  justify-content: flex-start;
  @media (max-width: 800px) {
    width: 100%;
  }
`;

const ToolsGrid = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
`;

const ToolImage = styled.img`
  height: 40px;
  width: auto;
  border-radius: 0;
  margin-right: 15px;
`;

const TextStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

class ProjectOverview extends Component {

  render() {

    const { finalProduct, duration, toolsUsed, teamMembers, myRole, methodsUsed, accentColor } = this.props;

    const toolImages = toolsUsed.map((imgSrc, key) =>
        <LazyLoad><ToolImage src={imgSrc}/></LazyLoad>
    )

    const Subtitle = styled.p`
      margin: 0;
      font-weight: bold;
      color: ${accentColor};
    `;

    return (
      <Content>
        <ItemGrid>
          <Item>
            <Subtitle>FINAL PRODUCT</Subtitle>
            <Description>{finalProduct}</Description>
          </Item>

          <Item>
            <Subtitle>TEAM MEMBERS</Subtitle>
            <Description>{teamMembers}</Description>
          </Item>

          <Item>
            <Subtitle>DURATION</Subtitle>
            <Description>{duration}</Description>
          </Item>

          <Item>
            <Subtitle>MY ROLE</Subtitle>
            <Description>{myRole}</Description>
          </Item>

          <Item>
            <Subtitle>TOOLS USED</Subtitle>
            <ToolsGrid>{toolImages}</ToolsGrid>
          </Item>

          <Item>
            <Subtitle>METHODS USED</Subtitle>
            <Description>{methodsUsed}</Description>
          </Item>
        </ItemGrid>
      </Content>
    );
  }
}

export default ProjectOverview;
