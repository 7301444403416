import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import dayviewThumbnail from '../Assets/dayviewThumbnail.png';
import LazyLoad from 'react-lazyload';

const Content = styled.div`
  vertical-align: top;
  display: inline-block;
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 1200px) {
    width: auto;
  }

  @media (max-width: 800px) {
    width: auto;
  }
`;

const HoverContainer = styled.div`
  padding: 0;
  border-radius: 12px;
  margin: 0 auto;
`;

const HStack = styled.div`
    margin-left: 0;
    flex-direction: column;
`;

const VStack = styled.div`
  display: flex;
  flex-direction: column;
  align-items: leading;
  margin-left: 0px;
  @media (max-width: 1200px) {
    margin-left: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: auto;
  border-radius: 10px;
  margin-bottom: 10px;

  @media (max-width: 1200px) {
    width: 100%;
  }

  transition: filter 500ms;
  &:hover {
    filter: contrast(120%);
  }
`;

const Title = styled.h2`
  margin: 0;
  text-align: left;
  color: black;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
`;

const Caption = styled.p`
  opacity: 0.4;
  margin: 0;
  text-align: left;
  margin-top: 5px;
  color: black;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  @media (max-width: 1200px) {
  }
`;

const Description = styled.p`
  text-align: left;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 0;
  color: black;
  @media (prefers-color-scheme: dark) {
    color: white;
  }
  @media (max-width: 1200px) {
    width: 70%;
  }
`;

class ProjectLockup extends Component {


  doThatThingSoICanPretendImWorking() {
    var yes = "aw yeah"
    
  }

  render() {

    const { title, caption, description, linkTo, image, year } = this.props;

    return (
      <Content>
        <Link to={linkTo}>
          <HoverContainer>
              <HStack>
                <LazyLoad>
                  <Image src={image}/>
                </LazyLoad>
                <VStack>
                  <Title>{title}</Title>
                  <Caption>{year} | {caption}</Caption>
                  <Description>{description}</Description>
                </VStack>
              </HStack>
          </HoverContainer>
        </Link>
      </Content>
    );
  }
}

export default ProjectLockup;
