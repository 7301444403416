import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  HaitchOne,
  BackText,
  DefaultBlock,
  HStack,
  HStackChild,
  ImageDiv,
  Image
} from '../Components/PageComponents.jsx';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import styled from 'styled-components';
import ARPackingInBag from '../Assets/ARPackingInBag.png';
import ARPackingItems from '../Assets/ARPackingItems.png';

const H1 = styled.h1`
  margin-top: 20px;
`;

class ARPackingAssistantScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Packing Assistant | AR Concepts';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <PaddedBlock>
          <H1>Packing Assistant</H1>
          <p>This is a mockup of an app that would take the guesswork out of packing for travel by suggesting the optimal way to arrange everything in your suitcase.</p>
          <p>First, you move your device's camera around all of the items you'll be packing, allowing it to estimate the volume of each item.</p>
          <ImageDiv>
            <Image src={ARPackingItems}/>
          </ImageDiv>
          <br/>
          <p>Next, it walks you through the steps of packing your suitcase, showing "holograms" of each item in the place where you should put it.</p>
          <ImageDiv>
            <Image src={ARPackingInBag}/>
          </ImageDiv>
          <br/>
          <p>A major assumption this design makes is that the app would be able to estimate the size of each item with relative precision. While this probably wouldn't be possible today, the direction of ARKit development seems to indicate that it would be one day.</p>
          <br/>
          <Link to='/ar'>
            <BackText>Back</BackText>
          </Link>
        </PaddedBlock>
      </PageContainer>
    );
  }
}

export default ARPackingAssistantScreen;
