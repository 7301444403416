import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  BannerImage,
  HaitchOne,
  TextStack,
  HStack,
  HStackChild,
  HStackTripleChild,
  Image,
  SplitImage,
  HalfImage,
  InvertableImage,
  Cframe,
  Protoframe,
  Comment,
  BackText,
  DefaultBlock
} from '../Components/PageComponents.jsx';
import ropeHero from '../Assets/ropeHero.png';
import ropeAppIcon from '../Assets/RopeAppIcon.png';
import ropeEmpathy1 from '../Assets/RopeEmpathy1.JPG';
import ropeEmpathy2 from '../Assets/RopeEmpathy2.JPG';
import ropeEmpathy3 from '../Assets/RopeEmpathy3.JPG';
import ropeBrainstorm1 from '../Assets/RopeBrainstorm1.jpg';
import ropeBrainstorm2 from '../Assets/RopeBrainstorm2.png';
import ropeCompRopedIn from '../Assets/RopeCompRopedIn.png';
import ropeCompStats from '../Assets/RopeCompStats.png';
import ropeCompInvite from '../Assets/RopeCompInvite.png';
import ropePactMockup from '../Assets/RopePactMockup.png';
import ropeGroupMediaMockup from '../Assets/RopeGroupMediaMockup.png';
import ropeRopeMockup from '../Assets/RopeRopeMockup.png';
import ropeEvolution from '../Assets/RopeEvolution.png';
import ropeAllComps from '../Assets/RopeAllComps.png';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import figma from '../Assets/Tools/figma.png';
import sketch from '../Assets/Tools/sketch.png';
import styled from 'styled-components';

const H1 = styled.h1`
  color: #c20e56;
`;

class RopeScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Rope | Case Study';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <BannerImage src={ropeHero}/>
        <PaddedBlock>
          <HaitchOne>Rope</HaitchOne>
          <p>Rope is an app meant to curb phone usage in social settings by encouraging friends to enter a "no-phones pact" while together.</p>
          <p>This was my project for my “Global Challenges in Design” class at Stanford, which lasted from March to June 2018. I worked on a team of four, with each of us <b>evenly dividing both the user research and visual design work</b>. Figma allowed us to delegate ourselves to particular flows and portions of the prototypes, and we all conducted our own user interviews, prototype tests, and visual design contributions, often in pairs.</p>
          <br/>
          <ProjectOverview
            finalProduct='Figma medium-fidelity prototype'
            duration='10 weeks'
            toolsUsed={[figma, sketch]}
            teamMembers='Eni Asebiomo, Francesca Colombo, Marisa Kwiatkowski'
            myRole='User researcher, visual designer'
            methodsUsed='Needfinding interview, empathy mapping, experience prototypes, interactive prototypes, usability testing'
            accentColor='#c20e56'
          />


          <H1>Overview</H1>

          <h2>The Problem</h2>
          <p>Mobile phone addiction is a real problem that can affect our social lives by disincentivizing us to talk to each other or live in the moment. Many of the people we spoke to told us about times when they got dinner or hung out with a friend or loved one, only to have them be preoccupied with their phone. Others we talked to wished that they could feel less of a social obligation to always be attentive to their phone.</p>
          <br/>
          <h2>Our Solution</h2>
          <p>We prototyped an iOS app called Rope, which aims to reduce mobile phone usage by leveraging social pressure. When you're spending time with friends, for example going out to eat together, you can take open the Rope app and invite your friends to "rope in" their phones with yours. By doing this, you create a pact with your friends to not use your phones for the duration of the meal.
          </p>
          <br/>

          <h2>How It Works</h2>
          <br/>
          <h3>1. Add Your Friends</h3>
          <p>Rope uses your contacts list and Bluetooth to find out which of your friends are nearby.</p>
          <Image src={ropeCompInvite}/>
          <br />
          <br />
          <h3>2. Start the Pact</h3>
          <p>Once everyone's in, everyone’s phone enters a "locked mode" until the pact is completed. Using other apps ends the pact.</p>
          <Image src={ropeCompRopedIn}/>
          <br />
          <br />
          <h3>3. View Your Stats</h3>
          <p>Your profile page contains stats on all of your previous pacts, including any group photos you attached.</p>
          <Image src={ropeCompStats}/>
          <br />
          <br />
          <h4>Our overall process:</h4>
          <ol>
            <li>Literature and Competitive Research
            </li><li>Interviews and Empathy Mapping
            </li><li>Brainstorming and Experience Prototypes
            </li><li>Low-Fidelity Prototypes
            </li><li>Medium-Fidelity Prototypes
            </li>
          </ol>


          <H1>User Research</H1>

          <h2>We started with a literature and competitive review, then moved on to interviews.</h2>
          <p>The goal of our initial research was first to validate our problem space and then to find out which challenge to tackle head-on. We started by reading several articles and journals covering the rise of phone addiction and its possible health risks.</p>
          <h4>The potential health impacts we saw:</h4>
          <ul>
            <li>High blood pressure
            </li><li>Anxiety and depression
            </li><li>Eye strain, neck strain
            </li><li>Potential link to cancer
            </li>
          </ul>
          <p>We also did a competitive review, where we looked for other apps and services aimed at reducing phone use. Keep in mind that we were 8 out of 10 weeks into this project when Apple announced its Screen Time feature.</p>
          <h4>What we found:</h4>
          <ul>
            <li>There are plenty of apps that aim to reduce tech use, but most have technical limitations
            </li><li>Moment seemed to have the strongest user base and the best overall design, but there are things it can't do due to iOS app restrictions
            </li><li>Moment itself was being promoted on the App Store (prior to Screen Time's release), indicating that people are interested in such apps
            </li><li>No apps tackle the social aspects of putting your phone away
            </li>
          </ul>
          <br/>

          <h2>Next, we interviewed people to hear their experiences with phone addiction.</h2>
          <p>We talked to some college students, some recent alumni, and some local parents.</p>
          <blockquote>
            "The last time I didn't have [my phone] with me... to be honest, I can't remember a time like that."
          </blockquote>
          <blockquote>
            "I stop [a phone session] when I get bored of browsing. I decide to do something else, probably take a nap."
          </blockquote>
          <blockquote>
            "When my daughter has her phone on the dinner table, it's a sign that something on the phone will be more important than what's at the table and reacting to it."
          </blockquote>
          <br />

          <h2>Then, we made empathy maps for our interviewees.</h2>
          <HStack>
            <HStackTripleChild><Image src={ropeEmpathy1}/></HStackTripleChild>
            <HStackTripleChild><Image src={ropeEmpathy2}/></HStackTripleChild>
            <HStackTripleChild><Image src={ropeEmpathy3}/></HStackTripleChild>
          </HStack>

          <p>We were surprised to find that although most people accept that phone addiction is a prevalent issue, <b>most people don't know about the physical and psychological harm</b>, often dismissing it. We also found that some people are okay with others using their phones while talking to them, while <b>others find it rude and disrespectful.</b></p>
          <p>We then generated three Point of View statements based on our most compelling interviews, then brainstormed a list of 15 how-might-we questions for each one.</p>
          <h4>How might we...</h4>
          <ul>
            <li>leverage social pressure to create phone-free environments?</li>
            <li>bring phone-related health concerns to the forefront?</li>
            <li>help people check their phones less without feeling like they're missing out?</li>
          </ul>
          <HStack>
            <HStackChild>
              <Image src={ropeBrainstorm1}/>
            </HStackChild>
            <HStackChild>
              <Image src={ropeBrainstorm2}/>
            </HStackChild>
          </HStack>
          <br />
          
          <H1>Interaction Design</H1>
          <h2>After brainstorming, we came up with three possible solutions to explore.</h2>
          <p>I wireframed the Group Social Media concept and part of the Pact concept while my team members did the rest.</p>
          <br/>
          <ol>
            <li><b>Pact</b> - a mobile app that lets people challenge their friends to not use their phones while they're together</li>
            <Image src={ropePactMockup}/>
            <br/>
            <br/>
            <li><b>Group Social Media</b> - a TV or desktop app that lets friends consume an aggregate of their social media feeds together</li>
            <Image src={ropeGroupMediaMockup}/>
            <br/>
            <br/>
            <li><b>Rope (our wild card)</b> - a physical string you would take out during meals or in a social setting to tether everyone's phones together to prevent distraction</li>
            <Image src={ropeRopeMockup}/>
            <br/>
            <br/>
          </ol>
          <br />
          <p>    
          Next, we wanted to conduct experiments to validate these ideas, so we each recruited some other students and tested out each idea.</p>
          <h4>Here's what we learned:</h4>
          <ul>
            <li>Most people would only enter a Pact with with a select group of friends
            </li><li>Those who would use Pact would be worried about missing important notifications
            </li><li>The group social media experience seemed to require a sort of lifestyle change for people, so it's unlikely to be used
            </li><li>People enjoyed using Rope while eating dinner together because they took it as a challenge to not use their phones together
            </li>
          </ul>


          <H1>Visual Design</H1>

          <h2>We decided to move forward with the Pact idea, but adopt the Rope theme.</h2>
          <p>We had initially come up with the implementation for Rope as a joke, like</p>
          <blockquote>If we wanna stop compulsively checking our phones, why not just tie them together with a rope?</blockquote>
          <p>But after conducting our experiments with Pact and Rope, we noticed that while people liked the idea of the Pact more and were more likely to use it, the Rope prototype brought an element of fun to avoiding their phones, further selling the concept that they took a challenge together.</p>
          <p>So, we rebranded the Pact to just be Rope, where you rope your friends' phones in when you spend time with them. I created the app icon using Sketch, and we started working on an interactive medium-fidelity prototype.</p>
          <br />
          <HalfImage src={ropeAppIcon}/>
          <br />
          <br />
          <p>All of our team members worked together on the visual design, with each of us designing separate parts of the interface and periodically sharing out our designs to get feedback and ensure consistency.</p>
          <p>
            <Image src={ropeAllComps}/>
          </p>
          <p>We used Figma so we could collaborate together remotely, but I found it to be limiting in a couple of the cases I was using it for, so I designed some assets in Sketch (like the rope) and imported them into Figma.</p>


          <H1>Prototyping</H1>
            <Protoframe src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FshvD4l4LEV5UCtzkB3B4lw%2FRope-Portfolio-Copy%3Fnode-id%3D435%253A401%26scaling%3Dmin-zoom" allowfullscreen></Protoframe>
    			<br/>
    			<br/>
    			<h2>Usability Testing</h2>
    			<h4>We recruited a few groups of students to do usability testing on our prototype, giving them these tasks:</h4>
    			<ol>
    				<li><b>Initiate</b> a pact (Complex)</li>
    				<li>Check pact <b>status</b> during a pact (Easy)</li>
    				<li><b>Conclude</b> a pact (Medium)</li>
    				<li>Check pact <b>history</b> (Medium)</li>
    			</ol>

    			<h4>And the most crucial bits of feedback we got were:</h4>
    			<ol>
    				<li>The flows of <b>inviting other people to a pact and joining one</b> needed to be more clear</li>
    				<li>Our Stats/Friends/Settings pages were <b>overly-complicated and overwhelming</b> to users</li>
    				<li>Our UI was generally regarded as clean, but there were <b>too many options that distracted from the main goal</b></li>
    			</ol>

    			<p>We went through two rounds of usability testing and iteration to get to the final interactive prototype you see above.</p>
    			<Image src={ropeEvolution}/>
    			<br />
    			<H1>Reflection</H1>
    			<p>I had a great time working on this project for a number of reasons. Our team had a very balanced set of skills and we worked together well, we were able to interview and conduct usability testing with a variety of different people, and I think we came up with a unique solution to a common problem.
    			</p>
          <Link to='/'>
            <BackText>Back</BackText>
          </Link>
        </PaddedBlock>
      </PageContainer>
    );
  }
}

export default RopeScreen;
