import React, { Component } from 'react';
import styled from 'styled-components';
import stanfordLogoLight from '../Assets/stanfordLogoLight.png';
import appleLogoLight from '../Assets/appleLogoLight.png';
import { Link } from 'react-router-dom';

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 0px;
  margin-top: 0;
  @media (max-width: 800px) {
  }
`;

const StanfordThumbnail = styled.img`
  width: 14px;
  padding-bottom: 2px;
  height: auto;
  margin-right: 1px;
  border-radius: 0;

  @media (prefers-color-scheme: dark) {
    filter: invert(100%);
  }
  @media (max-width: 800px) {
    width: 11px;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const AppleThumbnail = styled.img`
  width: 17px;
  padding-bottom: 4px;
  height: auto;
  border-radius: 0;

  @media (prefers-color-scheme: dark) {
    padding-bottom: 2px;
    filter: invert(100%);
  }
  @media (max-width: 800px) {
    width: 14px;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const ItemLockup = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 30px;
  @media (max-width: 600px) {
    padding-right: 20px;
  }
`;

const Text = styled.p`
  margin-left: 10px;

  text-align: center;
  @media (max-width: 800px) {
    font-size: 0.8em;
  }
  @media (max-width: 600px) {
    margin-left: 0;
    font-size: 0.8em;
  }
`;

const AboutMeLink = styled(Link)`
  padding: 5px 10px 5px 10px;
  border-radius: 12px;
  margin-bottom: 3px;
  background: rgba(207, 217, 217, 0.5);
  @media (prefers-color-scheme: dark) {
    background: rgba(207, 217, 217, 0.2);
  }
  @media (max-width: 800px) {
    font-size: 0.8em;
  }

  &:hover {
      background: rgba(207, 217, 217, 0.9);
      cursor: pointer;

      @media (prefers-color-scheme: dark) {
        background: rgba(207, 217, 217, 0.4);
      }
  }
`;


class SummaryBar extends Component {

  render() {

    const { showAboutLink } = this.props;

    return (
      <Content>
        <ItemLockup>
          <StanfordThumbnail src={stanfordLogoLight} />
          <Text>Stanford Class of '19</Text>
        </ItemLockup>
        <ItemLockup>
          <AppleThumbnail src={appleLogoLight} />
          <Text>Currently at Apple</Text>
        </ItemLockup>

        <AboutMeLink to="/about">More about me</AboutMeLink>
      </Content>
    );
  }
}

export default SummaryBar;
