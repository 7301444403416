import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  HaitchOne,
  BackText,
  DefaultBlock,
  HStack,
  HStackChild,
  ImageDiv,
  Image
} from '../Components/PageComponents.jsx';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import styled from 'styled-components';
import ARRunningLyricsThumbnail from '../Assets/ARRunningLyricsThumbnail.png';

const H1 = styled.h1`
  margin-top: 20px;
`;

class ARRunningLyricsScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Running with Lyrics | AR Concepts';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <PaddedBlock>
          <H1>Running with Lyrics</H1>
          <p>When running, I've noticed that it's often easier to run longer on a treadmill than outdoors, and it seems that a factor contributing to that is the difficulty of maintaining a constant pace while running outdoors. Without constantly looking down at your Apple Watch, it's difficult to always keep track of your pace.</p>
          <p>This mockup shows what a running experience could look like using a <b>head&#8209;mounted display</b> (no, it doesn't involve running around with an iPad). By leveraging the fact that many people listen to music on their runs, this solution turns individual lyrics into pace markers for the user to run to.</p>
          <br/>
          <Image src={ARRunningLyricsThumbnail}/>
          <br/>
          <h2>How It Works</h2>
          <p>Depending on a desired pace you set before starting your run, and on the timing of the lyrics in your playlist's songs, the app would place song lyrics into the real world, spaced in such a way that running between them at your desired pace should keep the song going like normal. You can imagine it like Guitar Hero - start to fall behind and the song will also slow down to indicate that you should pick up the pace.</p>
          <br/>
          <h2>Design Decisions Made</h2>
          <ol>
          <li>The previous and next lyrics should be gaussian blurred to emulate shallow depth of field and to make it easier to focus on the current lyric.
          </li><li>The current lyric is shown in the app tint color and has a faint dark gradient behind it to highlight it against the (most likely) crowded visuals of the user's running environment.
          </li><li>Lyrics are always placed above the horizon line, around the user's eye level, to reduce the likelihood of the text covering any 
          </li>
          </ol>
          <p>There are also some opportunities to improve the user experience here through engineering tweaks. If the lyric text happens to overlay with some important real-world text like a street sign, the lyric could dodge out of the way of the sign. There should also be some tolerance for imperfections, so if you don't exactly meet the desired pace in-between lyrics, future lyrics will be slightly repositioned to accommodate it. While this concept wouldn't truly be useful until HMDs are available to consumers, ARKit 4 and Apple Music's timed lyric data make this app possible to build today.</p>
          <br/>
          <Link to='/ar'>
            <BackText>Back</BackText>
          </Link>
        </PaddedBlock>
      </PageContainer>
    );
  }
}

export default ARRunningLyricsScreen;
