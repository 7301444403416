import React, { Component } from 'react';
import styled from 'styled-components';
import NavBar from '../Components/NavBar.jsx';
import IntroBlurb from '../Components/IntroBlurb.jsx';
import ProjectsGrid from '../Components/ProjectsGrid.jsx';
import AboutScreen from './AboutScreen.jsx';
import {
  DefaultBlock,
  Image,
  UnlinkedImage,
  ExtLink
} from '../Components/PageComponents.jsx';
import otherCritica from '../Assets/otherCritica.png';
import otherRhymepad from '../Assets/otherRhymepad.png';
import otherFoodgroup from '../Assets/otherFoodgroup.png';
import projectBannerSchedule from '../Assets/projectBannerSchedule.png';
import projectBannerRecord from '../Assets/projectBannerRecord.png';
import projectBannerLEDInspector from '../Assets/projectBannerLEDInspector.png';
import projectBannerARtender from '../Assets/projectBannerARtender.png';
import LazyLoad from 'react-lazyload';
import { Link } from 'react-router-dom';

const OverlayContainer = styled.div`
  position: relative;
  margin: 0 auto;

  position: relative;
  width: 100%;

  @media(max-width: 800px) {
    width: 100%;
  }
`;

const ContentContainer = styled.div`
  max-width: 1100px;
  margin: 0 auto;
  @media (max-width: 1200px) {
    width: 700px;
  }
  @media (max-width: 800px) {
    width: 80%;
  }
  max-width: 700px;
`;

const Footnote = styled.p`
  opacity: 0.5;
  margin: 0 auto;
  margin-top: 60px;
  margin-bottom: 30px;
  font-size: 10pt;
`;

const ContentHeader = styled.h2`
  text-align: left;
  margin-bottom: 20px;
  opacity: 0.5;
`;

const ContentSubheader = styled.p`
  text-align: left;
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1em;
  opacity: 0.5;
`;

const OtherProjectsGrid = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const OtherProjectLink = styled.a`
  width: 32%;
  height: auto;

  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 20px;
  }

  &:hover {
      opacity: 0.7;
  }
`;

const OtherProjectsSpacer = styled.div`
  height: 30px;
`;

const ExplorationSpacer = styled.div`
  height: 10px;
  @media (max-width: 600px) {
      height: 0;
  }
`;

const ExplorationGrid = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
      display: block;
  }

  justify-content: space-between;
`;

function ExplorationItem(props) {
  const ExplorationLink = styled(Link)`
    width: 49.3%;
    height: auto;

    @media (max-width: 600px) {
      width: 100%;
    }

    &:hover {
        opacity: 0.7;
    }
  `;

  const ExplorationImage = styled.img`
    background: rgba(207, 217, 217, 0.4);
    @media (prefers-color-scheme: dark) {
      background: rgba(207, 217, 217, 0.12);
    }
    border-radius: 12px;
    @media (max-width: 600px) {
      margin-bottom: 10px;
    }
  `;
  return <ExplorationLink to={props.to}>
    <LazyLoad>
      <ExplorationImage src={props.src}/>
    </LazyLoad>
  </ExplorationLink>
}

const MoreLink = styled(Link)`
  text-align: left;
  display: block;
  margin-top: 20px;
  font-weight: bold;
  &:hover {
    opacity: 0.6;
  }
`;

class HomeScreen extends Component {

  state = {
    showAbout: false
  }

  onAboutClicked(event) {
    this.setState({ showAbout: true })
  }

  onAboutDismissed() {
    this.setState({ showAbout: false })
  }

  constructor(props) {
    super(props);
    this.onAboutClicked = this.onAboutClicked.bind(this);
    this.onAboutDismissed = this.onAboutDismissed.bind(this);
  }

  componentDidMount() {
    window.scroll(0, 0);
    document.title = 'Punya Chatterjee';
  }

  render() {

    const { showAbout } = this.state;

    return (
        <OverlayContainer>
          <DefaultBlock>
            <NavBar usePlain={true}/>
          </DefaultBlock>
          <ContentContainer>
            <IntroBlurb onAboutClicked={this.onAboutClicked}/>

            <ContentHeader>Full Case Studies</ContentHeader>
            {/*<ContentSubheader>Full-length projects created from scratch</ContentSubheader>*/}
            <ProjectsGrid />
            <OtherProjectsSpacer />

            <ContentHeader>Concepts</ContentHeader>
            <ExplorationGrid>
              <ExplorationItem
                to='/recordstore'
                src={projectBannerRecord}
              />
              <ExplorationItem
                to='/schedulesharing'
                src={projectBannerSchedule}
              />
            </ExplorationGrid>
            <ExplorationSpacer />
            <ExplorationGrid>
              <ExplorationItem
                to='/artender'
                src={projectBannerARtender}
              />
              <ExplorationItem
                to='/arledinspector'
                src={projectBannerLEDInspector}
              />
            </ExplorationGrid>
            {/*}<MoreLink to='/ar'>All Augmented Reality Concepts →</MoreLink>*/}
            <OtherProjectsSpacer />
            <OtherProjectsSpacer />

            <ContentHeader>Other Past Projects</ContentHeader>
            {/*<ContentSubheader>Past projects hosted on my Dribbble</ContentSubheader>*/}
            <OtherProjectsGrid>
              <OtherProjectLink href='https://dribbble.com/shots/9075765-Critica-Web-App'>
                <UnlinkedImage src={otherCritica}/>
              </OtherProjectLink>
              <OtherProjectLink href='https://dribbble.com/shots/9075842-RhymePad-iOS-app'>
                <UnlinkedImage src={otherRhymepad}/>
              </OtherProjectLink>
              <OtherProjectLink href='https://dribbble.com/shots/9076359-FoodGroup-Concept'>
                <UnlinkedImage src={otherFoodgroup}/>
              </OtherProjectLink>
            </OtherProjectsGrid>
            <OtherProjectsSpacer />
            <Footnote>(This site was designed and developed by me as well)</Footnote>
          </ContentContainer>
        </OverlayContainer>
    );
  }
}

export default HomeScreen;
