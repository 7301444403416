import React, { Component } from 'react';
import styled from 'styled-components';
import pcLogo from '../Assets/pcLogo.png';
import SummaryBar from './SummaryBar.jsx';
import { Link } from 'react-router-dom';

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const HelloHeader = styled.h1`
  font-size: 40pt;
  font-weight: normal;
  margin: 20px;
  margin-left: 0;
  padding-top: 0;
  text-align: left;
  @media (max-width: 800px) {
    font-size: 30pt;
  }
`;

const Subheader = styled.h2`
  max-width: 620px;
  text-align: left;
  margin-top: 10px;
  opacity: 0.5;
  @media (max-width: 800px) {
    font-size: 17pt;
  }
`;

const GradientText = styled.h1`
  font-size: 40pt;
  font-weight: bold;
  display: inline;
  @media (max-width: 800px) {
    font-size: 30pt;
  }
  ${'disabled gradient' /*

  font-size: 42pt;
  color: #2AC45E;
  background: -webkit-linear-gradient(#54E497, #2AC45E);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */}
`;

class IntroBlurb extends Component {

  render() {

    const { showAboutLink, onAboutClicked } = this.props;

    return (
      <Content>
        <HelloHeader><GradientText>Punya Chatterjee</GradientText></HelloHeader>
        <Subheader>UI&nbsp;Engineer + UX&nbsp;Designer</Subheader>
        <SummaryBar />
      </Content>
    );
  }
}

export default IntroBlurb;
