import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  HaitchOne,
  BackText,
  DefaultBlock,
  HStack,
  HStackChild,
  ExtLink,
  Image
} from '../Components/PageComponents.jsx';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import styled from 'styled-components';
import ledInspectorScreen1 from '../Assets/ledInspectorScreen1.png';
import ledInspectorScreen2 from '../Assets/ledInspectorScreen2.png';
import LazyLoad from 'react-lazyload';

const H1 = styled.h1`
  margin-top: 20px;
`;

class ARLEDInspectorScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'LED Inspector | AR Concepts';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <PaddedBlock>
          <H1>LED Inspector</H1>
          <div class='embed-container-thin'><iframe src='https://player.vimeo.com/video/409671291?autoplay=1&loop=1' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
          <br/>
          <p>During my senior year at Stanford, I took a Human-Computer Interaction Research class where the goal of our final project was to prototype a novel interaction between users and technology. My team included a PhD student named Jackie Yang who had started doing some work on a system that involved letting LEDs transmit messages to camera-equipped devices by blinking at imperceptably fast speeds, essentially transmitting bits over video feed. His final research paper on the topic is <ExtLink href='https://jackieyang.me/files/infoled.pdf'>here</ExtLink>.</p>
          <br/>

          <h2>Motivation</h2>
          <p>The purpose of this technology was to make it so that household and public devices that conventionally only have space for an indicator LED could relay more information to users without including a physical screen and without a network connection.</p>
          <p>This would open up use cases like finding out exactly why your WiFi router isn't working (instead of just seeing the onboard error LED blink). In a future world where devices that use this LED protocol are ubiquitous, you would be able to find out more about everyday appliances just by pointing your phone camera at them. It would also enable direct manipulation of these electronics that normally are not designed to be interactive.</p>
          <p>My team's work on this project mostly involved us prototyping the transmission of data from a blinking LED to an iPhone X with a very basic interface, so I went back and made these mockups of what the UI could look like if it was built in to iOS.</p>
          <br/>
          <br/>

          <h2>Process</h2>
          <p>I used Sketch to create the 2D UI elements, then brought them into Cinema 4D to 3D track the camera and place the elements into the 3D field. I used After Effects to animate the transition that happens when you tap the info card and it expands, modeling it off of the similar transition in iOS's Measure app.</p>
          <HStack>
            <HStackChild><Image src={ledInspectorScreen1}/></HStackChild>
            <HStackChild><Image src={ledInspectorScreen2}/></HStackChild>
          </HStack>
          <br/>
          <Link to='/'><BackText>Back</BackText></Link>
        </PaddedBlock>
      </PageContainer>
    );
  }
}

export default ARLEDInspectorScreen;
