import React from 'react';
import logo from './logo.svg';
import './App.css';
import styled from 'styled-components';
import { HashRouter, Route, Switch } from 'react-router-dom';
import SplashScreen from './Screens/SplashScreen';
import HomeScreen from './Screens/HomeScreen';
import AboutScreen from './Screens/AboutScreen';
import SolasScreen from './Screens/SolasScreen';
import RopeScreen from './Screens/RopeScreen';
import DayViewScreen from './Screens/DayViewScreen';
import LaffyScreen from './Screens/LaffyScreen';
import ARScreen from './Screens/ARScreen';
import ARTenderScreen from './Screens/ARTenderScreen';
import ARLEDInspectorScreen from './Screens/ARLEDInspectorScreen';
import ARPackingAssistantScreen from './Screens/ARPackingAssistantScreen';
import ARRunningLyricsScreen from './Screens/ARRunningLyricsScreen';
import ScheduleSharingScreen from './Screens/ScheduleSharingScreen';
import RecordStoreScreen from './Screens/RecordStoreScreen';
import SoftwareScreen from './Screens/SoftwareScreen';

const LightDarkApp = styled.div`

`;

const MSwitch = styled(Switch)`
  position: relative;

  & > div {
    position: absolute;
    width: 100%;
  }
`;

function App() {
  return (
    <LightDarkApp className="App">
      <HashRouter>
        <MSwitch
          atEnter={{ opacity: 0 }}
          atLeave={{ opacity: 0 }}
          atActive={{ opacity: 1 }}
          className="switch-wrapper"
        >
          <Route exact path="/" component={SplashScreen} className="switch-wrapper-child"/>
          <Route exact path="/apps" component={HomeScreen} className="switch-wrapper-child"/>
          <Route path="/about" component={AboutScreen} className="switch-wrapper-child"/>
          <Route path="/solas" component={SolasScreen} className="switch-wrapper-child"/>
          <Route path="/rope" component={RopeScreen} className="switch-wrapper-child"/>
          <Route path="/dayview" component={DayViewScreen} className="switch-wrapper-child"/>
          <Route path="/laffy" component={LaffyScreen} className="switch-wrapper-child"/>
          <Route path="/ar" component={ARScreen} className="switch-wrapper-child"/>
          <Route path="/augmentedreality" component={ARScreen} className="switch-wrapper-child"/>
          <Route path="/artender" component={ARTenderScreen} className="switch-wrapper-child"/>
          <Route path="/arledinspector" component={ARLEDInspectorScreen} className="switch-wrapper-child"/>
          <Route path="/arpackingassistant" component={ARPackingAssistantScreen} className="switch-wrapper-child"/>
          <Route path="/arrunninglyrics" component={ARRunningLyricsScreen} className="switch-wrapper-child"/>
          <Route path="/schedulesharing" component={ScheduleSharingScreen} className="switch-wrapper-child"/>
          <Route path="/recordstore" component={RecordStoreScreen} className="switch-wrapper-child"/>
          <Route path="/software" component={SoftwareScreen} className="switch-wrapped-child"/>
        </MSwitch>
      </HashRouter>
    </LightDarkApp>
  );
}

export default App;
