import React, { Component } from 'react';
import styled from 'styled-components';
import NavBar from '../Components/NavBar.jsx';
import { DefaultBlock } from '../Components/PageComponents.jsx';
import skillGraphSkilled from '../Assets/skillGraphSkilled.png';
import skillGraphUsed from '../Assets/skillGraphUsed.png';
import avatar from '../Assets/avatar.jpg';
import location from '../Assets/location.png';
import {
  HStack,
  ExtLink
} from '../Components/PageComponents.jsx';

const Container = styled.div`
  position: relative;
  width: 100%;

`;

const PaddedContainer = styled.div`
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
  flex-direction: row;
  display: flex;
  @media(max-width: 800px) {
    flex-direction: column;
  }
`;

const PrimaryContent = styled.div`
  flex-grow: 3;
  padding-bottom: 50px;
  @media(max-width: 800px) {
    width: 100%;
  }
`;

const SkillGraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 200px;
  margin: 0 auto;
  @media(max-width: 800px) {
    padding-left: 0;
  }
`;

const GraphImage = styled.img`
  padding: 0;
  padding-top: 15px;
  margin: 0;
  object-fit: cover;
  border-radius: 0;
`;

const AvatarImage = styled.img`
  width: 300px;
  height: 300px;
  border-radius: 150px;
  margin: 0 auto;
  margin-top: 40px;
`;

const LocThumbnail = styled.img`
  width: 20px;
  padding-bottom: 0px;
  border-radius: 0;

  @media (prefers-color-scheme: light) {
    filter: invert(100%);
  }
`;

const Text = styled.p`
  margin-left: 10px;
  text-align: left;
`;

const MainText = styled.p`
  padding-top: 0;
  padding-bottom: 40px;
  max-width: 700px;
  margin: 0 auto;
  text-align: center;
  width: 90%;
`;

const Header = styled.h1`
  text-align: center;
  margin-top: 30px;
`;

class AboutScreen extends Component {

  onKeydown(event) {
    if (event.keyCode === 27) {
      const { onDismissed } = this.props;
      if (onDismissed) {
        onDismissed()
      }
    }
  }

  constructor(props) {
    super(props);
    this.onKeydown = this.onKeydown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeydown);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'About';
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeydown);
  }

  render() {
    const { present } = this.props;

    return (
      <Container present visible>

        <DefaultBlock>
          <NavBar usePlain={true}/>
        </DefaultBlock>
        <PaddedContainer>
          <PrimaryContent>
            <AvatarImage src={avatar}/>
            <Header>About Me</Header>
            <MainText>
            <p>I'm Punya. I grew up in Austin, TX, I studied Computer Science with a concentration in Human&#8209;Computer&nbsp;Interaction at Stanford University, and I work as a UI-focused software engineer at Apple in Cupertino. I am also an independent <ExtLink href='http://punyavisuals.com'>filmmaker</ExtLink>, which I've been doing for over ten years now.</p>
            <br/>
            <p>I have experience with all stages of the product design lifecycle, although visual design and interaction design are where my interest mostly lies. Each of my case studies exemplifies my work as a visual and interaction designer, as well as my user research and testing process.</p>
            <br/>
            <ExtLink>
              <a href="mailto:punya@alumni.stanford.edu">punya@alumni.stanford.edu</a>
            </ExtLink>
            </MainText>
            <SkillGraphContainer>
              <h4>Skilled With</h4>
              <GraphImage src={skillGraphSkilled}/>
              <br/>
              <br/>
              <h4>Have Used</h4>
              <GraphImage src={skillGraphUsed}/>
            </SkillGraphContainer>
          </PrimaryContent>
        </PaddedContainer>
      </Container>
    );
  }
}

export default AboutScreen;
