import React, { Component } from 'react';
import styled from 'styled-components';
import NavBar from '../Components/NavBar.jsx';
import { DefaultBlock } from '../Components/PageComponents.jsx';
import bwAvatar from '../Assets/bwAvatar.JPG';
import location from '../Assets/location.png';
import { Link } from 'react-router-dom';
import {
  VStack,
  HStack,
  ExtLink
} from '../Components/PageComponents.jsx';

const Container = styled.div`
  position: relative;
  width: 100%;

`;

const PaddedContainer = styled.div`
  max-width: 1100px;
  width: 90%;
  margin: 0 auto;
  flex-direction: row;
  display: flex;
  @media(max-width: 800px) {
    flex-direction: column;
  }
`;

const PrimaryContent = styled.div`
  flex-grow: 3;
  padding-bottom: 50px;
  @media(max-width: 800px) {
    width: 100%;
  }
`;

const AvatarImage = styled.img`
  width: 120px;
  height: 120px;
  border-radius: 150px;
  margin: 0 auto;
  margin-top: 180px;
`;

const H3 = styled.h2`
	opacity: 0.8;
`;

const A = styled.a`
  margin-top: 10px;

  &:hover {
  	color: rgb(61, 121, 215);
  }
`;

const InternalLink = styled(Link)`
  margin-top: 4px;

  &:hover {
  	color: rgb(61, 121, 215);
  }
`;

const SubtitleText = styled.p`
	opacity: 0.6;
`;

const MainText = styled.p`
  padding-top: 0;
  padding-bottom: 40px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  width: 90%;
`;

const Header = styled.h1`
  text-align: center;
  margin-top: 30px;
  color: rgb(61, 121, 215);
`;

const Spacer = styled.div`
  width: 80px;

  @media(max-width: 800px) {
    height: 60px;
  }
`;

class SplashScreen extends Component {

  onKeydown(event) {
    if (event.keyCode === 27) {
      const { onDismissed } = this.props;
      if (onDismissed) {
        onDismissed()
      }
    }
  }

  constructor(props) {
    super(props);
    this.onKeydown = this.onKeydown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('keydown', this.onKeydown);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Punya Chatterjee | Filmmaking, Comedy, Apps';
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onKeydown);
  }

  render() {
    const { present } = this.props;

    return (
      <Container present visible>

        <DefaultBlock>
        </DefaultBlock>

        <PaddedContainer>
          <PrimaryContent>
            <AvatarImage src={bwAvatar}/>
            <Header>Punya Chatterjee</Header>
            <MainText>
	            <SubtitleText>I'm here to do three things</SubtitleText>
	            
	            <br/>

	            <HStack>
	            	<VStack>
	            		<H3>Filmmaking</H3>
		              	<A href="https://punyavisuals.com">Punya Visuals (Portfolio)</A>
		              	<A href="https://vimeo.com/punya">Vimeo</A>
	            	</VStack>

	            	<Spacer/>

	            	<VStack>
	            		<H3>Comedy</H3>
		              	<A href="https://youtube.com/geekslayer73">GeekSlayer (YouTube channel)</A>
		              	<A href="https://www.youtube.com/stanfordrobberbarons/">Robber Barons ('15-'19)</A>
	            	</VStack>

	            	<Spacer/>

	            	<VStack>
	            		<H3>Apps</H3>
		              	<InternalLink to="/apps">UI Design/Dev Portfolio</InternalLink>
		              	<A href="https://www.linkedin.com/in/punyasc">LinkedIn</A>
	            	</VStack>
	            </HStack>

            </MainText>
          </PrimaryContent>
        </PaddedContainer>
      </Container>
    );
  }
}

export default SplashScreen;
