import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  HaitchOne,
  BackText,
  DefaultBlock,
  HStack,
  HStackChild,
  Image
} from '../Components/PageComponents.jsx';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import styled from 'styled-components';
import artenderPage1 from '../Assets/artenderPage1.jpg';
import artenderPage2 from '../Assets/artenderPage2.jpg';
import artenderPage3 from '../Assets/artenderPage3.jpg';
import artenderPage4 from '../Assets/artenderPage4.jpg';
import artenderScreen1 from '../Assets/artenderScreen1.png';
import artenderScreen2 from '../Assets/artenderScreen2.png';

const H1 = styled.h1`
  margin-top: 20px;
`;

const HStackFourChild = styled.div`
  width: 24%;
  @media(max-width: 800px) {
    width: 100%;
    margin-top: 5px;
  }
`;

class ARTenderScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'ARtender | AR Concepts';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <PaddedBlock>
          <H1>ARtender</H1>
          <div class='embed-container-thin'>
            <iframe 
              src='https://player.vimeo.com/video/409670950?autoplay=1&loop=1' 
              frameborder='0' 
              webkitAllowFullScreen 
              mozallowfullscreen 
              allowFullScreen
            >
            </iframe>
          </div>
          <br/>
          <p>ARtender is an app that lets you precisely make complex cocktails without having to manually measure out ingredients.</p>
          <br/>

          <h2>Motivation</h2>
          <p>The AR UI is designed to make it easy to hold either an iPhone or iPad in one hand while pouring ingredients with the other. The visual design of the ring is intended to make it easy to see what level to pour ingredients to at any viewing angle.</p>
          <p>This interactive level eliminates the need to use a shot glass or measuring glass, as all you have to do is pour each ingredient to the superimposed line when instructed to.</p>
          <p>While the level of precision in volume detection that this demo uses may not be possible today, it's reasonable to believe that it would be in the near future.</p>
          <br/>

          <h2>Browsing UI</h2>
          <p>The browsing UI lets you find new cocktails to make, and tells you upfront what ingredients will be needed. The 3D drink preview on the left adds a bit of delight and gives you an idea of how your drink will turn out.</p>
          <div class='embed-container'><iframe src='https://player.vimeo.com/video/409671634?autoplay=1&loop=1' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
          <br/>
          <br/>

          <h2>Process</h2>
          <p>After figuring out the possible scope of the concept, I made several sketches of possible UI elements and flows on my iPad, finally settling on one primary flow, which I wireframed. I also sketched out the other interaction considerations, like hand positioning on the device and visibility for the fill ring.</p>
          <HStack>
            <HStackFourChild><Image src={artenderPage1}/></HStackFourChild>
            <HStackFourChild><Image src={artenderPage2}/></HStackFourChild>
            <HStackFourChild><Image src={artenderPage3}/></HStackFourChild>
            <HStackFourChild><Image src={artenderPage4}/></HStackFourChild>
          </HStack>
          <br/>
          <p>I used Cinema 4D to create the models and to perform 3D camera tracking. I created the 2D UI elements in Sketch and brought them into After Effects for compositing with the footage.</p>
          <HStack>
            <HStackChild><Image src={artenderScreen1}/></HStackChild>
            <HStackChild><Image src={artenderScreen2}/></HStackChild>
          </HStack>
          <br/>
          <Link to='/'>
            <BackText>Back</BackText>
          </Link>
        </PaddedBlock>
      </PageContainer>
    );
  }
}

export default ARTenderScreen;
