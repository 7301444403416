import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  HaitchOne,
  BackText,
  DefaultBlock,
  HStack,
  HStackChild,
  ImageDiv,
  Image,
  InvertableImage,
  ProtoLink
} from '../Components/PageComponents.jsx';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import styled from 'styled-components';
import ledInspectorScreen1 from '../Assets/ledInspectorScreen1.png';
import ledInspectorScreen2 from '../Assets/ledInspectorScreen2.png';
import protoSymbolFramer from '../Assets/protoSymbolFramer.png';
import recordStoreSketch from '../Assets/recordStoreSketch.png';
import recordStoreFlows from '../Assets/recordStoreFlows.png';

const H1 = styled.h1`
  margin-top: 20px;
`;

const ExtLink = styled.a`
  text-decoration: underline;
`;

class RecordStoreScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Record Store App | Concepts';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <PaddedBlock>
          <H1>Digital Record Store</H1>
          <div class='embed-container-thin'><iframe src='https://player.vimeo.com/video/423304104?autoplay=1&loop=1' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
          <br/>
          <br/>

          <ProtoLink 
            to='https://framer.com/share/Record-Store-App-8MkCLjXVLomYRAPoOKwk/SgZe5APpD?fullscreen=1' 
            icon={protoSymbolFramer} 
            title='Open Framer prototype' 
            color='#27AE60'
          />
          <br/>
          <br/>
          <h2>Motivation</h2>
          <p>While there are of course hundreds of places to listen to music online, a lot of people (myself included) enjoy the experience of going to a record store and sifting through the racks of records, flipping through covers until you find one that seems interesting. Due to the effects of COVID-19, most record stores in California and other states have had to switch to a curbside pickup-only model. While this undoubtedly impacts their ability to do business, it also takes away much of the appeal of going to a record store.</p>
          <p>This prototype conceptualizes an app that could be used by various record stores to provide an online storefront for their catalogs, one that gives users at least a little bit of the "record store feel."</p>
          <p>It connects the experience of buying a physical record to previewing it over your existing music streaming subscription, and also shows you what the actual record itself looks like, something not possible in a physical store.</p>
          <br/>
          <br/>
          <h2>Process</h2>
          <p>I heavily drew inspiration from Apple's Cover Flow and used touches of skeumorphism to let the app act as a proxy for flipping through records in a store and discovering unexpected ones. I started by drawing some rough sketches of what the interface could look like, mostly to visualize how the browsing UI would transition into the purchase UI.</p>
          <p>I chose to make the prototype in Framer since it was the only program that supported 3D tilting animations, which was needed for the Cover Flow look. I made the record crates on the landing page using Sketch and brought them into Framer.</p>
          <br/>
          <HStack>
            <HStackChild>
              <InvertableImage src={recordStoreSketch}/>
            </HStackChild>
            <HStackChild>
              <Image src={recordStoreFlows}/>
            </HStackChild>
          </HStack>
          <br/>
          <Link to='/'><BackText>Back</BackText></Link>
        </PaddedBlock>
      </PageContainer>
    );
  }
}

export default RecordStoreScreen;
