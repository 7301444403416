import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  HaitchOne,
  BackText,
  DefaultBlock,
  HStack,
  HStackChild,
  ImageDiv,
  Image,
  InvertableImage,
  ProtoLink
} from '../Components/PageComponents.jsx';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import styled from 'styled-components';
import ledInspectorScreen1 from '../Assets/ledInspectorScreen1.png';
import ledInspectorScreen2 from '../Assets/ledInspectorScreen2.png';
import protoSymbolFigma from '../Assets/protoSymbolFigma.png';
import scheduleSharingSketch from '../Assets/scheduleSharingSketch.png';
import scheduleSharingFlows from '../Assets/scheduleSharingFlows.png';

const H1 = styled.h1`
  margin-top: 20px;
`;

class ScheduleSharingScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'Schedule Sharing | Concepts';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <PaddedBlock>
          <H1>Schedule Sharing</H1>
          <div class='embed-container-thin'><iframe src='https://player.vimeo.com/video/423304926?autoplay=1&loop=1' frameborder='0' webkitAllowFullScreen mozallowfullscreen allowFullScreen></iframe></div>
          <br/>
          <br/>
          <ProtoLink 
            to='https://www.figma.com/proto/ViP6UtNvWoAwYPXSdmnQzJ/Available?node-id=4%3A282&scaling=scale-down' 
            icon={protoSymbolFigma} 
            title='Open Figma prototype' 
            color='#27AE60'
          />
          <br/>
          <br/>
          <h2>Motivation</h2>
          <p>Often times when you share your availability with someone over email, you have to look through your calendar for the days you're interested in, then type out your availability manually.</p>
          <blockquote>
            <p>Yes, on Monday I'll be free between 2 and 4,</p>
            <p>on Tuesday I'll be free before noon,</p>
            <p>on Wednesday...</p>
          </blockquote>
          <p>While you could use a separate calendar sharing app that involves sending a link for someone to fill out, it would be nice to remove this extra step and introduce an in-line solution for email.</p>
          <p>This prototype shows a potential flow that could be triggered inside Gmail. In order to make it fit well with Gmail, I used a standard material design look.</p>
          <br/>
          <br/>
          <h2>Process</h2>
          <p>I started by drawing a couple rough variations of flows, then fleshing out this wireframe sketch. I then used Figma to build all the screens and turn them into a prototype. Most of the components were made from scratch, with me trying to match the standard look and feel of material design components. I also wanted the prototype to be as complete as possible, so a user could navigate back and forth through the full flow and see everything, animations included.</p>
          <br/>
          <HStack>
            <HStackChild>
              <InvertableImage src={scheduleSharingSketch}/>
            </HStackChild>
            <HStackChild>
              <Image src={scheduleSharingFlows}/>
            </HStackChild>
          </HStack>
          <br/>
          <Link to='/'>
            <BackText>Back</BackText>
          </Link>
        </PaddedBlock>
      </PageContainer>
    );
  }
}

export default ScheduleSharingScreen;
