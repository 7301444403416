import React, { Component } from 'react';
import styled from 'styled-components';
import ARProjectLockup from './ARProjectLockup';
import ARLEDInspectorThumbnail from '../Assets/ARLEDInspectorThumbnail.png';
import ARtenderThumbnail from '../Assets/ARtenderThumbnail.png';
import ARPackingAssistantThumbnail from '../Assets/ARPackingAssistantThumbnail.png';
import ARRunningLyricsThumbnail from '../Assets/ARRunningLyricsThumbnail.png';

const Grid = styled.div`
  width: 100%;
  display: inline-block;
`;

class ARProjectsGrid extends Component {

  render() {
    return (
      <Grid>
        <ARProjectLockup
          title='ARtender'
          image={ARtenderThumbnail}
          description='Make complex drinks without the measurements'
          linkTo='/artender'
        />
        <ARProjectLockup
          title='LED Inspector'
          image={ARLEDInspectorThumbnail}
          description="Get more info about your devices without a display"
          linkTo='/arledinspector'
        />
        <ARProjectLockup
          title='Running with Lyrics'
          image={ARRunningLyricsThumbnail}
          description="A novel way to keep your pace while jogging and listening to music"
          linkTo='/arrunninglyrics'
        />
        <ARProjectLockup
          title='Packing Assistant'
          image={ARPackingAssistantThumbnail}
          description='Automatically figure out the best way to pack your suitcase'
          linkTo='/arpackingassistant'
        />
      </Grid>
    );
  }
}

export default ARProjectsGrid;
