import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  PageContainer,
  PaddedBlock,
  BannerImage,
  HaitchOne,
  TextStack,
  HStack,
  HStackChild,
  HStackTripleChild,
  Image,
  SplitImage,
  HalfImage,
  InvertableImage,
  Cframe,
  Protoframe,
  Comment,
  BackText,
  DefaultBlock,
} from '../Components/PageComponents.jsx';
import dayviewHero from '../Assets/dayviewHero.png';
import dayviewAppIcon from '../Assets/dayviewAppIcon.png';
import dayviewWidgets1 from '../Assets/dayviewWidgets1.jpg';
import dayviewWidgets2 from '../Assets/dayviewWidgets2.png';
import dayviewSimpleWidgets from '../Assets/dayviewSimpleWidgets.png';
import dayviewDetailedWidgets from '../Assets/dayviewDetailedWidgets.png';
import dayviewMenuBar from '../Assets/dayviewMenuBar.png';
import NavBar from '../Components/NavBar.jsx';
import ProjectOverview from '../Components/ProjectOverview.jsx';
import dayviewChartsFree from '../Assets/dayviewChartsFree.jpg';
import dayviewChartsProductivity from '../Assets/dayviewChartsProductivity.jpg';
import principle from '../Assets/Tools/principle.png';
import sketch from '../Assets/Tools/sketch.png';
import xcode from '../Assets/Tools/xcode.png';
import styled from 'styled-components';

const H1 = styled.h1`
  color: #5296d9;
`;

class DayViewScreen extends Component {

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    document.title = 'DayView | Case Study';
  }

  render() {
    return (
      <PageContainer>
      	<DefaultBlock>
        	<NavBar usePlain={true}/>
      	</DefaultBlock>
        <BannerImage src={dayviewHero}/>
        <PaddedBlock>
        <HaitchOne>DayView</HaitchOne>
        <p>DayView is a personal dashboard app for Apple TV that lets you put up to six widgets on your screen, so you can turn on your TV and get your morning briefing.</p>
        <p>This app was <b>solely designed and developed by me</b>. It accumulated 37,000 users on the Apple TV App Store, peaking at #31 on the Top Free chart and staying at #1 in Productivity for 6 months.</p>
        <br/>

        <ProjectOverview
          finalProduct='tvOS app, released'
          duration='3 months development, 4 months maintenance'
          toolsUsed={[sketch, principle, xcode]}
          teamMembers='None'
          myRole='Sole designer and developer'
          methodsUsed='User interview, wireframing, usability testing, app analytics'
          accentColor='#5296d9'
        />

        <h2>The Problem</h2>
        <p>When you’re leaving for work in the morning, you often want to know things like how the weather is outside, how long it’ll take to get to work, and what’s on your calendar. Normally, you would have to check this information on your phone, either using individual apps or by scrolling through widgets. Additionally, many people have a large TV at home that sits dormant most of the time when nothing is being watched.</p>
        <br/>

        <h2>The Solution</h2>
        <p>DayView turns your TV into an ambient informational display, full of widgets that give you the information you want at a quick glance. The app includes widgets for Calendar, Weather, News, Stocks, Twitter, and HomeKit.</p>
        <br/>


        <H1>Process</H1>
        <p>My approach for this project was far more relaxed than my previous projects, <Link to='/solas'>Solas</Link>, <Link to='/rope'>Rope</Link>, and <Link to='/laffy/'>Laffy</Link>, since it was an independent side project for me. This gave me a pretty great amount of freedom when it came to designing the product and iterating on it after release.</p>
        <br />

        <h2>Designing the Primary Layout</h2>
        <Image src={dayviewWidgets2}/>
        <p>The most daunting task I had in front of me was figuring out the best layout for the widgets. The widgets themselves could look however they needed to (depending on what they’re used for), but I needed to determine the overall structure of the app.</p>

        <p>I started by brainstorming several different layouts, keeping TV safe area zones and interaction with the Siri Remote in mind. Some variations put more of an emphasis on widgets than others.</p>
        <br/>
        <Image src={dayviewWidgets1}/>
        <p>The final layout I came to was this 6-widget grid, where the second row is taller than the first and has a blurred background card around it. The intention was for “simple widgets” to go in the top row and for “detailed widgets” to go in the bottom row, although I gave users the freedom to put any widget wherever if they so choose.</p>
        <br/>

        <h3>Simple Widgets</h3>
        <p>I decided to enforce a single layout for the three simple widgets (Weather, Commute, and Time), to make sure they looked clean and consistent in the top row.</p>
        <Image src={dayviewSimpleWidgets}/>
        <br/>
        <br/>

        <h3>Detailed Widgets</h3>
        <p>The detailed widgets were all laid
          out either as a grid or a list.
          Since these widgets covered a wide variety of use cases, they all had different appearances.</p>
        <Image src={dayviewDetailedWidgets}/>
        <br/>
        <br/>
        <br/>

        <h2>Designing the Menu Bar</h2>
        <p>I wanted there to be a way for users to click on any of the widgets to change its settings or to get more info, but I also wanted it to be possible for users to just leave DayView up on their TV and let it run.</p>
        <Image src={dayviewMenuBar}/>
        <p>Interactions with the Apple TV are handled by something called the focus engine, which requires one object to appear “in focus” at all times. In order to make the widgets focusable, while still allowing people to leave the app up with nothing focused, I implemented a menu bar that drops down from the top when the remote is touched and then disappears 10 seconds later.</p>
        <p>This way, the menu bar buttons would hold the focus and then disappear shortly, leaving you with a clean display of your widgets.
        </p>
        <br />
        <br />

        <h2>Branding</h2>
        <p>When coming up with the app icon and associated branding for it, it took a while to decide on what the best representation of the app would be. There are no universally-known symbols for a dashboard, and the name DayView may not tell you much about the app without knowing what it is.
        <br/>
        <br/>
        After a brainstorming session where I came up with six different designs and had a dozen people independently give me their opinions, I settled on this icon, liking the day-themed color scheme and the card that represents widgets:</p>
        <br/>
        <HalfImage src={dayviewAppIcon}/>
        <br/>
        <br/>
        <H1>Release</H1>
        <p>When I initially released the app on the App Store, it was very much in a public beta-like state. Because so few people I know owned Apple TVs, I struggled to find people to conduct real user testing with, so I opted instead to publish the first version of the app and wait for feedback, as an in-the-field testing strategy. What I had not anticipated was the overwhelming response to this first version.</p>
        <p>I made a post about my app on the Apple TV subreddit on Reddit, hoping it would attract a few dozen users so I could get their thoughts on what works and what they want to see more of. To my surprise, the response of the subreddit was great, and a few tech blogs, including MacRumors, had written <a href='https://www.macrumors.com/2018/11/04/dayview-a-personal-dashboard-for-apple-tv/'>articles about it.</a></p>
        <p><b>It peaked as the #31 top free app on the tvOS App Store, and maintained its position at #1 in the Productivity category for the next 6 months.</b></p>
        <br/>
        <HStack>
          <HStackChild>
            <Image src={dayviewChartsFree}/>
          </HStackChild>
          <HStackChild>
            <Image src={dayviewChartsProductivity}/>
          </HStackChild>
        </HStack>

        <H1>Post-Release Iteration</H1>
        <h4>Thanks to this unexpected influx of users, I now had:</h4>
        <ul>
          <li>way more people to solicit for usability feedback on the app than I had intended</li>
          <li>a handful of disgruntled users emailing me with feedback because they (rightfully) expected this V1 to be more polished</li>
        </ul>
        <p>I made my email address very accessible in the app, and as a result, I got frequent emails from users who wanted to give me ideas for new widgets or features, and those who were finding challenges with the app.</p>
        <h4>Some of the top requests I had at the beginning:</h4>
        <ul>
          <li>integrate iCloud calendars, not just Google Calendar (I finally ended up doing this, but through a somewhat hacky way, due to restrictions Apple has on their Calendar API for TV)</li>
          <li>increase the clarity of navigation between the menu bar and the widgets themselves, especially when editing your widgets (I improved this by adding a solid background to the menu bar instead of leaving it as a floating set of buttons, and by rearranging the buttons and text prompt in the menu)</li>
          <li>several suggestions for new widgets, especially HomeKit widgets (which I did implement soon after)</li>
        </ul>

        <p>By the time I discontinued the app, it had reached version 1.4. Features that were not present in version 1.0 were HomeKit widgets, a detailed weather widget,  support for iCloud calendars, the addition of international news sources, and picking wallpapers from your iCloud photos or Unsplash’s stock photo library.</p>
        <br/>
        <h2>Paid Features</h2>
        <p>To support development, I included a set of features as a $2.99 in-app purchase in version 1.2, which would enable power users to have more than one of each widget on their screen (e.g. if you wanted multiple stocks or multiple calendar widgets). It also let them pick wallpapers from their iCloud library or stock photos from Unsplash.com. About 10% of the total users chose to purchase it.</p>
        <p>I discontinued the app in July when I began working for Apple, per my employment agreement. At that point, I made the in-app purchase features of the app free for all and made some final bug fixes.</p>
        <br/>
        <H1>Reflection</H1>
        <p>DayView is easily the project I am most proud of. I worked on it alone for several months, wearing all the hats of the development process, from conception to post-release iteration. This app gave me an opportunity to work with users in a realistic capacity for the first time, because they were people who were actually using and had opinions about a product I had created. </p>
        <p>The amount of feedback I received was invaluable because it let me get accustomed to accounting for several different people’s desires and thoughts on how the app should move forward, and let me distill just the most crucial parts to integrate into the app. </p>
        <Link to='/'>
          <BackText>Back</BackText>
        </Link>
      </PaddedBlock>
    </PageContainer>
    );
  }
}

export default DayViewScreen;
