import React, { Component } from 'react';
import styled from 'styled-components';
import ProjectLockup from './ProjectLockup';
import solasHero from '../Assets/solasHero.png';
import ropeHero from '../Assets/ropeHero.png';
import dayviewHero from '../Assets/dayviewHero.png';
import laffyHero from '../Assets/laffyHero.png';

const Grid = styled.div`
  width: 100%;
  display: inline-block;
`;

class ProjectsGrid extends Component {

  render() {
    return (
      <Grid>
        <ProjectLockup
          title='DayView'
          year='2019'
          caption='Personal'
          image={dayviewHero}
          description='Giving you all the info you need to start your day, at a single glance on your Apple TV'
          linkTo='/dayview'
        />
        <ProjectLockup
          title='Rope'
          year='2018'
          caption='Academic'
          image={ropeHero}
          description="Encouraging people of all ages to put their phones away when spending time together"
          linkTo='/rope'
        />
        <ProjectLockup
          title='Laffy'
          year='2018'
          caption='Academic'
          image={laffyHero}
          description='Giving stand-up comedy fans a unified platform to discover online and live content'
          linkTo='/laffy'
        />
        <ProjectLockup
          title='Solas'
          year='2017'
          caption='Academic'
          image={solasHero}
          description='Helping people moving to a new city make new friends and find places to go'
          linkTo='/solas'
        />
      </Grid>
    );
  }
}

export default ProjectsGrid;
