import React, { Component } from 'react';
import styled from 'styled-components';
import LazyLoad from 'react-lazyload';

const PageContainer = styled.div`
  padding-bottom: 50px;
  position: relative;
`;

const DefaultBlock = styled.div`
  margin: 0 auto;
  margin-left: 15px;
  margin-right: 20px;
  @media (max-width: 800px) {
    margin: 0 auto;
    width: 80%;
    align-items: flex-start;
    flex-direction: column;
  }
`;

const PaddedBlock = styled.div`
  margin: 0 auto;
  width: 80%;
  max-width: 700px;
  text-align: left;
`;

const HaitchOne = styled.h1`
  font-size: 60pt;
  margin: 0;
  margin-top: 40px;
  margin-bottom: 30px;
  @media (max-width: 800px) {
    margin-top: 30px;
    font-size: 40pt;
  }
`;

const TextStack = styled.div`
  text-align: left;
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &>ul {
    margin-top: 0;
    column-count: 2;
    column-fill: auto;
    &>li {
      margin-top: 10px;
      margin-right: 10px;
    }
    &>h4 {
      margin-top: 0;
    }
    margin-bottom: 40px;
  }

  @media (max-width: 800px) {
      margin-right: 0;
      &>ul {
        list-style-type: none;
      }
  }
`;

const VStack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;

const HStack = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media(max-width: 800px) {
    flex-direction: column;
  }
`;

const CaptionStack = styled.div`
  margin-left: 40px;
  margin-right: 40px;
`;

const HStackChild = styled.div`
  width: 49%;
  @media(max-width: 800px) {
    width: 100%;
    margin-top: 5px;
  }
`;

const HStackTripleChild = styled.div`
  width: 32%;
  @media(max-width: 800px) {
    width: 100%;
    margin-top: 5px;
  }
`;

const Cframe = styled.iframe`
  width: 100%;
  margin: 0 auto;
  border: none;
`;

const Protoframe = styled(Cframe)`
  display: block;
  width: 375px;
  height: 667px;
  margin: 0 auto;
`;

const Comment = styled.em`
  opacity: 0.5;
  line-height: 150%;
`;

const BackText = styled.p`
  font-weight: bold;
  margin: 0 auto;
  text-align: center;
  margin-top: 30px;
`;

const ProConGrid = styled.div`
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin-top: -20px;

  @media(max-width: 800px) {
    grid-template-columns: 1fr;
    margin-top: -10px;
  }
`;

const ImageDiv = styled.div`
  width: 50%;
  margin: 0 auto;

  @media (max-width: 800px) {
    width: 90%;
  }
`;

const ExtLink = styled.a`
  text-decoration: underline;
`;

function ProtoLink(props) {

  const Base = styled.a`
    margin-top: 200px;
    padding: 12px 15px 14px 15px;
    border-radius: 12px;
    background: rgba(0, 0, 0, 0.07);
    font-weight: bold;

    
    flex-direction: row;
    align-items: center;
    
    @media (prefers-color-scheme: dark) {
      background: rgba(255, 255, 255, 0.1);
    }

    &:hover {
      opacity: 0.6;
    }
  `;

  const Icon = styled.img`
    width: 14px;
    height: auto;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;

    @media (prefers-color-scheme: dark) {
      padding-bottom: 2px;
      filter: invert(100%) hue-rotate(180deg);
    }
    @media (max-width: 800px) {
      width: 14px;
    }
  `;

  const Text = styled.p`
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.7;
  `;

  return <Base href={props.to} background={props.color}>
      <Icon src={props.icon}/>
      <Text>{props.title}</Text>
  </Base>;
}

function BannerImage(props) {

  const BaseImage = styled.img`
    width: 100%;
    margin: 0 auto;
    border-radius: 0;
  `;

  return <LazyLoad>
    <BaseImage src={props.src}/>
  </LazyLoad>;
}

function Image(props) {
  return <LazyLoad offset={100}>
    <a href={props.src}>
      <img src={props.src}/>
    </a>
  </LazyLoad>;
}

function UnlinkedImage(props) {
  return <LazyLoad offset={100}>
    <img src={props.src}/>
  </LazyLoad>;
}

function SplitImage(props) {

  const BaseImage = styled.img`
    width: 100%;
    height: auto;
    object-fit: contain;
    max-width: 680px;
    margin: 0 auto;
  `;

  return <LazyLoad offset={100}>
    <a href={props.src}>
      <BaseImage src={props.src}/>
    </a>
  </LazyLoad>;
}

function HalfImage(props) {

  const BaseImage = styled.img`
    width: 50%;
    display: block;
    margin: 0 auto;

    @media(max-width: 800px) {
      width: 75%;
    }
  `;

  return <LazyLoad offset={100}>
    <a href={props.src}>
      <BaseImage src={props.src}/>
    </a>
  </LazyLoad>;
}

function InvertableImage(props) {

  const BaseImage = styled.img`
    @media (prefers-color-scheme: dark) {
      filter: invert(100%) hue-rotate(180deg);
    }
  `;

  return <LazyLoad offset={100}>
    <a href={props.src}>
      <BaseImage src={props.src}/>
    </a>
  </LazyLoad>;
}

export {
	PageContainer,
	PaddedBlock,
	BannerImage,
	HaitchOne,
	TextStack,
  VStack,
	HStack,
	HStackChild,
	HStackTripleChild,
  Image,
  UnlinkedImage,
	SplitImage,
	HalfImage,
	InvertableImage,
	Cframe,
	Protoframe,
	Comment,
	BackText,
	DefaultBlock,
  ProConGrid,
  CaptionStack,
  ImageDiv,
  ProtoLink,
  ExtLink
}